import { ChangeEvent, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useDebounce } from '@uidotdev/usehooks';
import { UsersTable } from './UsersTable';
import { Board } from '~/components/Board/Board';
import { ErrorMessage } from '~/components/ErrorMessage/ErrorMessage';
import { Button, InputBar, Pagination, Spinner } from '~/ui';
import '~/assets/css/select-table.css';
import styles from './UsersBoard.module.scss';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { useLocalStorage } from '~/utils/useLocalStorage';
import UserBoardActions from './UserBoardActions';
import { useTranslation } from 'react-i18next';
import { useToastError } from '~/utils/useToastError';

interface SelectedPage {
  selected: number;
}

interface Points {
  page: number;
}

interface Props {
  userTripStatus: string;
}

export const UsersBoard = ({ userTripStatus }: Props) => {
  const { initialValues, storedInitialValues, create } =
    useLocalStorage<Points>({
      initialValues: { page: 15 },
      key: 'users-points',
      exclude: [],
    });
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(
    storedInitialValues?.page || initialValues.page
  );
  const [page, setPage] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const toastError = useToastError();
  const client = useQueryClient();
  const { t } = useTranslation();

  useEffect(() => {
    create({ page: perPage });
  }, [perPage]);

  useEffect(() => {
    setPage(1);
  }, [userTripStatus]);

  const params = {
    sort: sort.direction,
    perPage,
    field: sort.field,
    query: debouncedSearchTerm,
    page,
    business_trip: userTripStatus,
  };

  const { data, isError, error } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/users', {
        params,
      });
      return response.data;
    },
    queryKey: ['users', params],
    keepPreviousData: true,
  });

  const users = data?.users?.data;

  let content: React.ReactNode;

  const handleSelect = (id: number) => {
    if (selectedItems.includes(id)) {
      const filteredUsers = selectedItems.filter(
        selectedId => selectedId !== id
      );
      setSelectedItems(filteredUsers);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleToggleAll = () => {
    if (selectedItems.length === data?.users?.data.length) {
      setSelectedItems([]);
    } else {
      const userIdArray = data?.users?.data.map((user: any) => user.id);
      setSelectedItems(userIdArray);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      await instance.delete('admin/users-many', {
        data: { user_ids: selectedItems },
      });
      setSelectedItems([]);
      client.invalidateQueries(['users']);
    } catch (error) {
      toastError(error);
    }
  };

  if (users) {
    content = (
      <UsersTable
        selectedItems={selectedItems}
        handleToggle={handleSelect}
        handleToggleAll={handleToggleAll}
        users={users}
        sort={sort}
        setSort={setSort}
      />
    );
  } else if (isError) {
    content = <ErrorMessage error={error} />;
  } else {
    content = <Spinner />;
  }

  return (
    <Board>
      <div className={styles.usersTableOptions}>
        <TableSelect perPage={perPage} setPerPage={setPerPage} />
        <div className={styles.tableHeadRightWrapper}>
          {!!selectedItems.length && (
            <Button
              text={t('delete')}
              color="red"
              onClick={handleDeleteSelected}
              className={styles.deleteButton}
            />
          )}
          <InputBar
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            value={searchTerm}
            placeholder={t('search_by_code_n_np')}
            classNameInput={styles.usersTableInput}
            label={`${t('search')}:`}
            star={false}
            classNameInputWrapper={styles.usersTableInputWrapper}
          />
          <UserBoardActions />
        </div>
      </div>
      {content}
      <div className={styles.usersTablePagination}>
        <TableAmountPage
          firstRow={data?.meta?.from}
          lastRow={data?.meta?.to}
          total={data?.meta?.total}
        />
        <Pagination
          forcePage={page || 1}
          pageCount={data?.meta?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};
