import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import {
  NotificationItem,
  NotificationValues,
  NotificationForm,
} from '~/components/NotificationForm';
import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import { Button } from '~/ui';
import dayjs from 'dayjs';
import styles from './EditNotification.module.scss';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';

const EditNotification = () => {
  const { notificationID } = useParams();
  const navigate = useNavigate();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();

  const client = useQueryClient();

  const { data: notification } = useQuery<NotificationItem>({
    queryFn: async () => {
      const response = await instance.get(`notification/${notificationID}`);
      return response.data;
    },
    queryKey: ['notification', notificationID],
    onError: error => {
      toastError(error);
    },
  });

  const { mutateAsync: deleteNotification } = useMutation(
    async (id: string) => {
      const response = await instance.delete(`notification/${id}`);
      return response.data;
    },
    {
      onSuccess: (data: NotificationItem) => {
        client.setQueryData(
          ['notifications'],
          (oldNotifications?: { data: NotificationItem[] }) => {
            const newNotification =
              oldNotifications?.data?.filter(
                notification => notification.id !== data.id
              ) || [];

            toastSuccess(t('notification_deleted'));
            return { data: newNotification };
          }
        );

        navigate('/notifications');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const selectedUserIds = notification?.user_id
    ? notification.user_id.map(item =>
        typeof item === 'number' ? item : item?.id
      )
    : [];

  const { mutate: updateNotification } = useMutation(
    async ({
      name,
      module,
      comment,
      accommodation_id,
      status_id,
      category_id,
      user_id,
      group_send,
      condition,

      date,
      time,
      time_zone,

      scenario_id,
      display,
      notification_organizations,
    }: NotificationValues) => {
      const delete_user_id = selectedUserIds
        ?.filter(
          itemId => !user_id?.find((userId: number) => userId === itemId)
        )
        .map(userId => userId);

      const notification: NotificationValues = {
        name,
        module,
        comment,
        accommodation_id,
        status_id,
        category_id,
        group_send,
        condition,
        delete_user_id: delete_user_id,
      };

      let typeNotification;
      if (condition === '2') {
        typeNotification = 'notification-now-send';
      } else if (condition === '1') {
        typeNotification = 'notification-data-send';
      } else if (condition === '3') {
        typeNotification = 'notification-scenario-send';
      }

      if (condition === '1') {
        notification.date = date;
        notification.time = dayjs(time, 'HH:mm').format('HH:mm');
        notification.time_zone = time_zone;
      }
      if (group_send === '2') {
        notification.user_id = user_id;
      }

      if (group_send === '3') {
        notification.organization_ids = notification_organizations?.map(
          organization => organization.id
        );
      }

      if (condition === '3') {
        notification.scenario_id = scenario_id;
        notification.display = display;
      }

      const response = await instance.patch(
        `${typeNotification}/${notificationID}`,
        notification
      );
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['notifications']);
        navigate('/notifications');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={notification?.name}
          classNameText={styles.editNotificationText}
          actions={
            <Button
              disabled={false}
              color="red"
              text={t('delete_notification')}
              onClick={() => deleteNotification(notificationID as string)}
            />
          }
        />
      }
    >
      <NotificationForm
        notification={notification}
        onSubmit={values => updateNotification(values)}
      />
    </Page>
  );
};

export default EditNotification;
