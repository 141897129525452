import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useDebounce } from '@uidotdev/usehooks';
import { Board } from '~/components/Board/Board';
import { ErrorMessage } from '~/components/ErrorMessage/ErrorMessage';
import { FeedbacksTable } from './FeedbacksTable';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { Button, InputBar, Pagination, Spinner } from '~/ui';
import '~/assets/css/select-table.css';
import styles from './FeedbacksBoard.module.scss';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { useToastError } from '~/utils/useToastError';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';

interface SelectedPage {
  selected: number;
}

interface Points {
  page: number;
}

export const FeedbacksBoard = () => {
  const { initialValues, storedInitialValues, create } =
    useLocalStorage<Points>({
      initialValues: { page: 15 },
      key: 'feedbacks-points',
      exclude: [],
    });
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(
    storedInitialValues?.page || initialValues.page
  );
  const [page, setPage] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { t } = useTranslation();
  const toastError = useToastError();
  const client = useQueryClient();
  const { user } = useContext(AuthenticationContext);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const isDeleteAvailable = checkIfUserSuperAdmin(user?.data.role_name);

  useEffect(() => {
    create({ page: perPage });
  }, [perPage]);

  const params = {
    sort: sort.direction,
    perPage,
    field: sort.field,
    query: debouncedSearchTerm,
    page,
  };

  const {
    data: feedbacks,
    isError,
    error,
  } = useQuery({
    queryFn: async () => {
      const response = await instance.get('/admin/feedback', { params });
      return response.data;
    },
    onError: error => {
      toastError(error);
    },
    queryKey: ['feedbacks', params],
    keepPreviousData: true,
  });

  const handleSelect = (id: number) => {
    if (selectedItems.includes(id)) {
      const filteredTransactions = selectedItems.filter(
        selectedId => selectedId !== id
      );
      setSelectedItems(filteredTransactions);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleToggleAll = () => {
    if (selectedItems.length === feedbacks?.feedbacks.data.length) {
      setSelectedItems([]);
    } else {
      const feedbacksIdArray = feedbacks?.feedbacks.data.map(
        (feedback: any) => feedback.id
      );
      setSelectedItems(feedbacksIdArray);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      await instance.delete('admin/feedback', {
        data: { feedback_ids: selectedItems },
      });
      setSelectedItems([]);
      client.invalidateQueries(['feedbacks']);
    } catch (error) {
      toastError(error);
    }
  };

  let content: React.ReactNode;

  if (feedbacks?.feedbacks?.data) {
    content = (
      <FeedbacksTable
        feedbacks={feedbacks?.feedbacks?.data}
        sort={sort}
        setSort={setSort}
        handleToggleAll={handleToggleAll}
        handleSelect={handleSelect}
        selectedItems={selectedItems}
      />
    );
  } else if (isError) {
    content = <ErrorMessage error={error} />;
  } else {
    content = <Spinner />;
  }

  return (
    <Board>
      <div className={styles.feedbacksTableOptions}>
        <TableSelect perPage={perPage} setPerPage={setPerPage} />
        <div className={styles.tableHeadRightWrapper}>
          {!!selectedItems.length && isDeleteAvailable && (
            <Button
              text={t('delete')}
              color="red"
              onClick={handleDeleteSelected}
              className={styles.deleteButton}
            />
          )}
          <InputBar
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            value={searchTerm}
            placeholder={t('category_search')}
            classNameInput={styles.feedbacksTableInput}
            label={`${t('search')}:`}
            star={false}
            classNameInputWrapper={styles.feedbacksTableInputWrapper}
          />
        </div>
      </div>
      {content}
      <div className={styles.feedbacksTablePagination}>
        <TableAmountPage
          firstRow={feedbacks?.feedbacks?.from}
          lastRow={feedbacks?.feedbacks?.to}
          total={feedbacks?.feedbacks?.total}
        />
        <Pagination
          pageCount={feedbacks?.feedbacks?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};
