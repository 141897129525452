import { instance } from '~/utils/api/api';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToastError } from '~/utils/useToastError';
import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import {
  NotificationForm,
  NotificationValues,
} from '~/components/NotificationForm';
import dayjs from 'dayjs';
import styles from './CreateNotification.module.scss';
import { useTranslation } from 'react-i18next';

const CreateNotification = () => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const toastError = useToastError();
  const { t } = useTranslation();

  const { mutateAsync: createNotification } = useMutation(
    async ({
      name,
      module,
      comment,
      accommodation_id,
      status_id,
      category_id,
      user_id,
      group_send,
      condition,
      date,
      time,
      time_zone,
      scenario_id,
      display,
      notification_organizations,
    }: NotificationValues) => {
      const notification: NotificationValues = {
        name,
        module: module,
        comment,
        accommodation_id,
        status_id,
        category_id,
        group_send,
        condition,
      };

      let typeNotification;
      if (condition === '2') {
        typeNotification = 'notification-now-send';
      } else if (condition === '1') {
        typeNotification = 'notification-data-send';
      } else if (condition === '3') {
        typeNotification = 'notification-scenario-send';
      }

      if (group_send === '2') {
        notification.user_id = user_id;
      }

      if (group_send === '3') {
        notification.organization_ids = notification_organizations?.map(
          organization => organization.id
        );
      }

      if (condition === '1') {
        notification.date = date;
        notification.time = dayjs(time).format('HH:mm');
        notification.time_zone = time_zone;
      }

      if (condition === '3') {
        notification.scenario_id = scenario_id;
        notification.display = display;
      }

      const response = await instance.post(`${typeNotification}`, notification);

      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['notifications']);
        navigate('/notifications');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={t('create_notification')}
          classNameText={styles.createNotificationText}
        />
      }
    >
      <NotificationForm
        onSubmit={values => createNotification(values)}
        storageKey="create-notification"
      />
    </Page>
  );
};

export default CreateNotification;
