import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { Badge, IconButton, IconButtonLink } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import ModalConfirm from '~/components/ModalConfirm';
import styles from './FoodTable.module.scss';
import { IAsset } from '~/components/AccommodationForm';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { useContext } from 'react';
import TooltipText from '~/components/Shared/TooltipText/TooltipText';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import { useToastSuccess } from '~/utils/useToastSuccess';

interface Accommodation {
  id: number;
  name: string;
}

export interface Food {
  id: number;
  name: string;
  company: string;
  food_accommodation: Accommodation[];
  description: string;
  is_active: boolean;
  asset: number;
  assets: IAsset;
}

interface FoodTableProps {
  foods: Food[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  handleToggleAll: () => void;
  handleToggle: (id: number) => void;
  selectedItems: number[];
}

export const FoodTable = ({
  foods,
  sort,
  setSort,
  handleToggleAll,
  handleToggle,
  selectedItems,
}: FoodTableProps) => {
  const { user } = useContext(AuthenticationContext);
  const isDeleteButtonShown = checkIfUserSuperAdmin(user?.data.role_name);
  const isAssetShown = checkIfUserSuperAdmin(user?.data.role_name);
  const client = useQueryClient();
  const { t } = useTranslation();
  const toastSuccess = useToastSuccess();

  const { mutate: deleteFood } = useMutation(
    async (id: string) => {
      const response = await instance.delete(`food-admin/${id}`);
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['food']);
        toastSuccess(t('food_point_removed'));
      },
    }
  );

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead
            className={styles.tableCellHead}
            sortable={false}
            field="checkbox"
          >
            <CustomCheckbox
              isChecked={
                selectedItems.length === foods.length && !!foods.length
              }
              onChange={handleToggleAll}
            />
          </TableCellHead>
          <TableCellHead field="name">{t('name')}</TableCellHead>
          <TableCellHead field="accommodation" sortable={false}>
            {t('accommodation_facility')}
          </TableCellHead>
          {isAssetShown && (
            <TableCellHead field="asset">{t('asset')}</TableCellHead>
          )}
          <TableCellHead field="description">{t('description')}</TableCellHead>
          <TableCellHead field="is_active">{t('status')}</TableCellHead>
          <TableCellHead sortable={false} field="actions">
            {t('action')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {foods.map(
            ({
              id,
              name,
              food_accommodation,
              description,
              is_active,
              assets,
            }: Food) => (
              <TableRow key={id}>
                <TableCell>
                  <CustomCheckbox
                    isChecked={selectedItems.includes(id)}
                    onChange={() => handleToggle(id)}
                  />
                </TableCell>
                <TableCell>{name}</TableCell>
                <TableCell className={styles.foodCell}>
                  <TooltipText
                    text={food_accommodation.map(({ name }) => name).join('; ')}
                  />
                </TableCell>
                {isAssetShown && <TableCell>{assets?.name}</TableCell>}
                <TableCell className={styles.foodCell}>
                  <TooltipText text={description} />
                </TableCell>
                <TableCell>
                  <Badge
                    className={styles.statusBadge}
                    variant={is_active ? 'success' : 'error'}
                  >
                    {is_active
                      ? t('order_available')
                      : t('order_not_available')}
                  </Badge>
                </TableCell>
                <TableCell>
                  <div className={styles.foodTableActions}>
                    {isDeleteButtonShown && (
                      <ModalConfirm
                        onConfirm={() => deleteFood(id.toString())}
                        title={`${t('sure_to_delete_food_point')} «${name}»?`}
                      >
                        {open => (
                          <IconButton onClick={open}>
                            <Basket />
                          </IconButton>
                        )}
                      </ModalConfirm>
                    )}
                    <IconButtonLink
                      to={`/food/${id}`}
                      className={styles.foodTableBtnLink}
                    >
                      <Edit />
                    </IconButtonLink>
                  </div>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};
