import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useState } from 'react';
import { ButtonLink, Spinner } from '~/ui';
import { Board } from '~/components/Board/Board';
import { PersonsFoodTable } from './PersonsFoodTable';
import { ErrorMessage } from '~/components/ErrorMessage/ErrorMessage';
import styles from './PersonsFoodBoard.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  foodId: number;
}

export const PersonsFoodBoard = ({ foodId }: Props) => {
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });

  const { t } = useTranslation();

  const params = {
    sort: sort.direction,
    field: sort.field,
    food_id: foodId,
  };

  const {
    data: foodPersons,
    isError,
    error,
  } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/food-person', {
        params,
      });
      return response.data;
    },

    queryKey: [['food-persons'], foodId, params],
    keepPreviousData: true,
  });

  let content: React.ReactNode;

  if (foodPersons?.data && foodId) {
    content = (
      <PersonsFoodTable
        foodPersons={foodPersons?.data}
        sort={sort}
        setSort={setSort}
        foodMenuId={foodId}
      />
    );
  } else if (isError) {
    content = <ErrorMessage error={error} />;
  } else {
    content = <Spinner />;
  }

  return (
    <Board>
      <div className={styles.options}>
        <ButtonLink
          to={`/food/${foodId}/persons/create`}
          text={t('add_responsible_person')}
          className={styles.btnLink}
          color="green"
        />
      </div>
      {content}
    </Board>
  );
};
