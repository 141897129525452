import { Accommodation } from '~/pages/Accomodotions/AccommodationsTable';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';

import styles from './QuestionnairesTable.module.scss';
import TooltipText from '~/components/Shared/TooltipText/TooltipText';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import Analytics from '~/assets/svg/newSvg/analytics-icon.svg?react';
import ModalConfirm from '~/components/ModalConfirm';
import { IconButton, IconButtonLink } from '~/ui';
import dayjs from 'dayjs';
import StatusBadge from './StatusBadge/StatusBadge';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';

export interface Questionnaire {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  publication_date: string;
  removal_date: string;
  type_id: number;
  closed: boolean;
  questionnaire_accommodations: Accommodation[];
  questionnaire_organizations: { id: number; name: string }[];
  questionnaire_status: { id: number; name: string; type: string };
  questionnaire_type: { id: number; name: string };
  questions: [];
  licensor: string;
}

interface IQuestionnairesTableProps {
  questionnaires: Questionnaire[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  clickConfirmDelete: (id: number) => void;
  selectedItems: number[];
  handleToggleAll: () => void;
  handleToggle: (id: number) => void;
}

const QuestionnairesTable = ({
  questionnaires,
  sort,
  setSort,
  clickConfirmDelete,
  selectedItems,
  handleToggleAll,
  handleToggle,
}: IQuestionnairesTableProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead
            className={styles.tableCellHead}
            sortable={false}
            field="checkbox"
          >
            <CustomCheckbox
              isChecked={
                selectedItems.length === questionnaires.length &&
                !!questionnaires.length
              }
              onChange={handleToggleAll}
            />
          </TableCellHead>
          <TableCellHead field="name">{t('name')}</TableCellHead>
          <TableCellHead sortable={false} field="accommodation">
            {t('accommodation_facility')}
          </TableCellHead>
          <TableCellHead field="type_id">{t('anquette_type')}</TableCellHead>
          <TableCellHead field="publication_date">
            {t('publication_date_anquette')}
          </TableCellHead>
          <TableCellHead field="status_id">{t('status')}</TableCellHead>
          <TableCellHead sortable={false} field="actions">
            {t('action')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {questionnaires.map(
            ({
              id,
              name,
              questionnaire_accommodations,
              publication_date,
              questionnaire_status,
              questionnaire_type,
            }: Questionnaire) => (
              <TableRow key={id}>
                <TableCell>
                  <CustomCheckbox
                    isChecked={selectedItems.includes(id)}
                    onChange={() => handleToggle(id)}
                  />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TooltipText text={name} />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TooltipText
                    text={questionnaire_accommodations
                      .map(({ name }: Accommodation) => name)
                      .join(', ')}
                  />
                </TableCell>
                <TableCell className={cn(styles.tableCell, styles.typeCell)}>
                  {questionnaire_type.name}
                </TableCell>
                <TableCell>
                  {dayjs(publication_date).format('DD.MM.YYYY')}
                </TableCell>
                <TableCell className={styles.statusCell}>
                  <div className={styles.statusBadgeContainer}>
                    <StatusBadge
                      type={questionnaire_status.type}
                      name={questionnaire_status.name}
                    />
                  </div>
                </TableCell>
                <TableCell>
                  <div className={styles.actions}>
                    <ModalConfirm
                      title={`${t('delete_confirm')} «${name}»`}
                      onConfirm={() => clickConfirmDelete(id)}
                    >
                      {open => (
                        <IconButton onClick={open}>
                          <Basket />
                        </IconButton>
                      )}
                    </ModalConfirm>
                    <IconButtonLink
                      to={`/questionnaires/${id}`}
                      className={styles.linkButton}
                    >
                      <Edit />
                    </IconButtonLink>
                    <IconButtonLink
                      to={`/questionnaires/${id}/results`}
                      className={styles.linkButton}
                    >
                      <Analytics />
                    </IconButtonLink>
                  </div>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default QuestionnairesTable;
