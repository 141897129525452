import { useToastError } from '~/utils/useToastError';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { validate } from '~/utils/validateAccmmd';
import { useLocalStorage } from '~/utils/useLocalStorage';
import UploadPhoto, {
  UploadPhotosValue,
} from '~/components/UploadPhoto/UploadPhoto';
import { InputBar, Button, Textarea } from '~/ui';
import styles from './AccommodationForm.module.scss';
import { instance } from '~/utils/api/api';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';
import CustomSelect from '../Shared/CustomSelect/CustomSelect';

interface PhotoItem {
  id: number;
  full_photo_url: string;
}

export interface AccommodationItem {
  id: number;
  name: string;
  description: string;
  address: string;
  floors: string;
  rooms: string;
  beds: string;
  photos?: PhotoItem[] | [];
  section: string | null;
  asset_id: number | null;
  full_name: string;
  assets: IAsset;
}

export interface AccommodationValues {
  name: string;
  description: string;
  address: string;
  floors: string;
  rooms: string;
  beds: string;
  photos?: PhotoItem[] | [];
  uploadPhotosValue: UploadPhotosValue;
  section: string | null;
  asset_id: number | null;
  assets: IAsset;
}

export interface IAsset {
  id: number;
  address: string;
  created_at: string;
  updated_at: string;
  name: string;
  inn: string;
  description: string;
}

interface AccommodationFormProps {
  onSubmit: (values: AccommodationValues) => Promise<void>;
  accommodation?: AccommodationItem;
  storageKey?: string;
  isAssetEditAvailable?: boolean;
  isPicturesDisabled?: boolean;
  isLoading?: boolean;
}

export const AccommodationForm = ({
  onSubmit,
  accommodation,
  storageKey,
  isPicturesDisabled,
  isAssetEditAvailable,
  isLoading,
}: AccommodationFormProps): JSX.Element => {
  const [assets, setAssets] = useState<IAsset[]>([]);
  const { t } = useTranslation();
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();

  const loadAssets = async () => {
    try {
      const { data } = await instance.get('admin/assets');
      setAssets(data.data);
    } catch {
      toastError(`${t('failed_to_load')} ${t('assets')}`);
    }
  };

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<AccommodationValues>({
      initialValues: {
        name: '',
        description: '',
        address: '',
        floors: '',
        rooms: '',
        beds: '',
        uploadPhotosValue: { photos: [], files: [] },
        section: null,
        asset_id: null,
        assets: {
          id: 0,
          address: '',
          created_at: '',
          updated_at: '',
          name: '',
          inn: '',
          description: '',
        },
      },
      key: storageKey && `${storageKey}-v1`,
      exclude: ['uploadPhotosValue'],
    });

  const formik = useFormik<AccommodationValues>({
    initialValues: storedInitialValues,

    validate,

    onSubmit: async values => {
      try {
        await onSubmit(values);
        clear();
        formik.resetForm({ values: initialValues });
        toastSuccess(t('accommodation_saved'));
      } catch (error) {
        toastError(error);
      }
    },
  });

  create(formik.values);

  const setValues = formik.setValues;

  useEffect(() => {
    if (!accommodation) return;

    setValues({
      assets: accommodation.assets,
      asset_id: accommodation.asset_id,
      name: accommodation.name,
      description: accommodation.description,
      address: accommodation.address,
      floors: accommodation.floors,
      rooms: accommodation.rooms,
      beds: accommodation.beds,
      uploadPhotosValue: {
        photos: accommodation?.photos || [],
        files: [],
      },
      section: accommodation.section,
    });
  }, [accommodation, assets, setValues]);

  useEffect(() => {
    loadAssets();
  }, []);

  return (
    <div className={styles.formWrapper}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formTop}>
          <InputBar
            name="name"
            value={formik.values.name}
            disabled={isLoading}
            label={t('name')}
            star={true}
            placeholder={t('item_name')}
            onChange={formik.handleChange}
            errors={formik.errors.name}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />

          {isAssetEditAvailable && (
            <CustomSelect
              placeholder={t('to_choose')}
              onChange={value => {
                formik.setFieldValue('assets', value);
              }}
              label={t('asset')}
              value={formik.values.assets}
              disabled={!isAssetEditAvailable || isLoading}
              options={assets}
              error={
                formik.touched.assets
                  ? (formik.errors.assets as string)
                  : undefined
              }
              onTouch={() => formik.setFieldTouched('assets', true)}
              required
            />
          )}
          <div className={styles.addressWrapper}>
            <InputBar
              name="address"
              value={formik.values.address}
              disabled={isLoading}
              label={t('address')}
              star={true}
              placeholder={t('address')}
              onChange={formik.handleChange}
              errors={formik.errors.address}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
            />
            <InputBar
              name="section"
              value={formik.values.section ? formik.values.section : ''}
              disabled={isLoading}
              label={t('section')}
              star={false}
              placeholder={t('section')}
              onChange={formik.handleChange}
              errors={formik.errors.section}
              onBlur={formik.handleBlur}
              error={formik.touched.section && Boolean(formik.errors.address)}
            />
          </div>

          <Textarea
            showCounter
            rows={4}
            label={t('description')}
            placeholder={t('description')}
            maxLength={1000}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={() => formik.setFieldTouched('text', true)}
            name="description"
            star={false}
            disabled={isLoading}
          />

          <div className={styles.inputsWrapper}>
            <InputBar
              name="floors"
              value={formik.values.floors}
              disabled={isLoading}
              label={t('Floors')}
              star={false}
              placeholder={`${t('Quantity')} ${t('of')} ${t('floors')}`}
              onChange={formik.handleChange}
              errors={formik.errors.floors}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.floors)}
            />

            <InputBar
              name="rooms"
              value={formik.values.rooms}
              disabled={isLoading}
              label={t('Rooms')}
              star={false}
              placeholder={`${t('Quantity')} ${t('rooms')}`}
              onChange={formik.handleChange}
              errors={formik.errors.rooms}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.rooms)}
            />

            <InputBar
              name="beds"
              value={formik.values.beds}
              disabled={isLoading}
              label={t('Sleeping_places')}
              star={false}
              placeholder={`${t('Quantity')} ${t('sleeping_places')}`}
              onChange={formik.handleChange}
              errors={formik.errors.beds}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.beds)}
            />
          </div>

          <UploadPhoto
            onChange={uploadPhotosValue => {
              formik.setFieldValue('uploadPhotosValue', uploadPhotosValue);
            }}
            values={formik.values.uploadPhotosValue}
            label={t('Photo')}
            disabled={isPicturesDisabled}
            isUploadBlocked={isPicturesDisabled}
          />
        </div>

        <Button
          type="submit"
          text={t('save_n_exit')}
          className={styles.formBtn}
          disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
        />
      </form>
    </div>
  );
};
