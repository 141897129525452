import { Select } from 'antd';
import { useState } from 'react';
import styles from './selectBar.module.scss';
import '~/assets/css/select.css';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

export interface OptionItem {
  id: string;
  name: string;
  [key: string]: string;
}

interface SelectBarProps<T> {
  errors?: string;
  error?: boolean;
  label?: string;
  options: OptionItem[];
  value?: T;
  onChange: (value: T) => void;
  star: boolean;
  el: string;
  disabled: boolean;
  valueItem?: string;
  name: string;
  className?: string;
  placeholder?: string;
}

const SelectBar = <T,>({
  errors,
  error,
  label,
  options,
  value,
  onChange,
  star,
  el,
  disabled,
  valueItem,
  className,
  placeholder,
}: SelectBarProps<T>): JSX.Element => {
  const { Option } = Select;
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (value: string) => {
    setSearchValue(value);
  };
  const { t } = useTranslation();

  const filteredOptions = options?.filter(
    item => item[el]?.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className={cn(styles.select, className)}>
      <label className={styles.selectLabel}>
        <span className={styles.selectText}>
          {label}
          {star ? <span className={styles.selectStar}>*</span> : null}
        </span>
        <div className="selectBarWrapper">
          <Select
            disabled={disabled}
            value={value}
            onChange={onChange}
            className="ant-select"
            placeholder={placeholder || t('to_choose')}
            onSearch={handleSearch}
            maxTagCount="responsive"
          >
            {filteredOptions?.map(item => (
              <Option
                key={item.id}
                value={
                  valueItem === 'name'
                    ? item.name
                    : item.id || valueItem === 'full_name'
                      ? item.full_name
                      : item.id
                }
              >
                <span>{item[el]}</span>
              </Option>
            ))}
          </Select>
          {error ? <div className={styles.errors__text}>{errors}</div> : null}
        </div>
      </label>
    </div>
  );
};

export default SelectBar;
