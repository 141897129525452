import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { inputColor, inputText } from './utils';
import Message from '~/assets/svg/newSvg/message.svg?react';
import { Button, InputBar, Textarea, IconButtonLink } from '~/ui/index';
import styles from './FeedbackProccesingForm.module.scss';
import UploadPhotoItem from '~/components/UploadPhoto/UploadPhotoItem/UploadPhotoItem';
import { getLocalTimeDate } from '~/utils/getLocalTimeDate';
import { VideoPlayer } from '~/components/VideoPlayer/VideoPlayer';
import { useTranslation } from 'react-i18next';

interface Photo {
  id: number;
  full_photo_url: string;
}

interface Video {
  id: number;
  full_video_url: string;
  full_preview_video_url: string;
}

interface FeedbackItem {
  category_name: string;
  object_name: string;
  user_name: string;
  grade_name: string;
  created_at: string;
  user_uniqueId: string;
  description: string;
  rating: {
    quality: string;
    cleanliness: string;
    staff: string;
  };
  feedback_photo: Photo[] | [];
  feedback_video: Video[] | [];
  feedback_categories_id: number;
}

interface FeedbackItemProps {
  feedback: FeedbackItem;
}

export const FeedbackProccesingForm = ({ feedback }: FeedbackItemProps) => {
  const navigate = useNavigate();
  const goBack = () => navigate('/feedback');
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const photos = feedback?.feedback_photo;
  let qualityText = '';

  if (feedback?.feedback_categories_id === 1) {
    qualityText = t('feedback_food');
  } else if (feedback?.feedback_categories_id === 2) {
    qualityText = t('feedback_accommodation');
  } else if (
    feedback?.feedback_categories_id === 3 ||
    feedback?.feedback_categories_id === 4
  ) {
    qualityText = t('feedback_quality');
  }

  const formik = useFormik({
    initialValues: {
      category_name: '',
      object_name: '',
      user_name: '',
      grade_name: '',
      description: '',
      date: '',
      quality: '',
      cleanliness: '',
      staff: '',
      uniqueID: '',
    },

    onSubmit: () => {
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (!feedback) return;

    formik.setValues({
      category_name: feedback.category_name,
      object_name: feedback.object_name,
      user_name: feedback.user_name,
      grade_name: feedback.grade_name,
      description: feedback.description,
      date: getLocalTimeDate(moment(feedback.created_at).format()),
      quality: feedback.rating ? feedback.rating.quality : '',
      cleanliness: feedback.rating ? feedback.rating.cleanliness : '',
      staff: feedback.rating ? feedback.rating.staff : '',
      uniqueID: feedback.user_uniqueId,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedback]);

  return (
    <div className={styles.formWrapper}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formItems}>
          <InputBar
            name="category_name"
            value={formik.values.category_name}
            disabled={true}
            label={t('category')}
          />
          <InputBar
            name="object_name"
            value={formik.values.object_name}
            disabled={true}
            label={t('object_place_order')}
          />
          <InputBar
            name="uniqueID"
            value={formik.values.uniqueID}
            disabled={true}
            label={t('unique_user_ID')}
          />
          <InputBar
            name="user_name"
            value={formik.values.user_name}
            disabled={true}
            label={t('employee')}
          />
          <InputBar
            name="grade_name"
            value={formik.values.grade_name}
            disabled={true}
            label={t('user_type')}
          />
          <Textarea
            rows={6}
            label={t('comment')}
            placeholder={t('comment')}
            maxLength={600}
            value={formik.values.description}
            onChange={formik.handleChange}
            name="description"
            star={false}
            disabled={true}
          />
          <InputBar
            name="date"
            value={formik.values.date}
            disabled={true}
            label={t('date_and_time')}
          />
          <div className={styles.ratingWrapper}>
            <InputBar
              name="quality"
              value={inputText(formik.values.quality)}
              disabled={true}
              label={qualityText}
              color={inputColor(formik.values.quality)}
            />
            {feedback?.feedback_categories_id === 1 ||
            feedback?.feedback_categories_id === 2 ? (
              <>
                <InputBar
                  name="cleanliness"
                  value={inputText(formik.values.cleanliness)}
                  disabled={true}
                  label={t('service')}
                  color={inputColor(formik.values.cleanliness)}
                />
                <InputBar
                  name="staff"
                  value={inputText(formik.values.staff)}
                  disabled={true}
                  label={t('employees_secondary')}
                  color={inputColor(formik.values.staff)}
                />
              </>
            ) : null}
          </div>
          <div className={styles.mediaContainer}>
            <div className={styles.imgContainer}>
              {photos?.map(({ id, full_photo_url }) => {
                return (
                  <UploadPhotoItem
                    key={id}
                    url={full_photo_url}
                    deletePhoto={false}
                  />
                );
              })}
            </div>
            {feedback
              ? (feedback as { feedback_video: Video[] }).feedback_video.map(
                  ({ id, full_video_url, full_preview_video_url }: Video) => (
                    <VideoPlayer
                      key={id}
                      url={full_video_url}
                      poster={full_preview_video_url}
                    />
                  )
                )
              : null}
          </div>
        </div>

        <div className={styles.btns}>
          <Button
            disabled={false}
            text={t('back_to_list')}
            className={styles.btn}
            onClick={goBack}
          />
          <IconButtonLink to={`${pathname}/comment`} className={styles.btnLink}>
            <span>{t('feedback_response')}</span>
            <Message />
          </IconButtonLink>
        </div>
      </form>
    </div>
  );
};
