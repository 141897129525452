import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import { ButtonLink } from '~/ui';
import { QrBoard } from './QrBoard';
import { useTranslation } from 'react-i18next';

const GeneratorQr = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page
      heading={
        <Heading
          text={t('qr')}
          actions={
            <>
              <ButtonLink text={t('qr_code_create')} to="/qr/create" />
            </>
          }
        />
      }
    >
      <QrBoard />
    </Page>
  );
};

export default GeneratorQr;
