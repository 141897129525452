import { useState } from 'react';
import { ErrorMessage } from '~/components/ErrorMessage/ErrorMessage';
import { Board } from '~/components/Board/Board';
import { Spinner } from '~/ui';
import { SocialTable } from './SocialTable';
import styles from './SocialBoard.module.scss';
import { useTranslation } from 'react-i18next';

export const SocialBoard = (): JSX.Element => {
  const approve = true;
  const empty = true;
  const { t } = useTranslation();

  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });

  let content: React.ReactNode;

  if (approve) {
    content = <SocialTable sort={sort} setSort={setSort} />;
  } else if (!approve) {
    content = <ErrorMessage />;
  } else {
    content = <Spinner />;
  }

  return (
    <Board classNames={styles.board}>
      {content}{' '}
      {empty && <p className={styles.boardEmpty}>{t('create_new_question')}</p>}
    </Board>
  );
};
