import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButton, IconButtonLink } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './NotificationsTable.module.scss';
import { renderStatus } from './utils';
import ModalConfirm from '~/components/ModalConfirm';
import TooltipText from '~/components/Shared/TooltipText/TooltipText';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import dayjs from 'dayjs';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useToastError } from '~/utils/useToastError';

export interface Notification {
  id: number;
  module_name: string;
  category_name: string;
  group_send: string;
  condition: string;
  comment: string;
  status_id: number;
  date: string;
}

interface NotificationsTableProps {
  notifications: Notification[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  selectedItems: number[];
  handleToggleAll: () => void;
  handleToggle: (id: number) => void;
}

export const NotificationsTable = ({
  notifications,
  sort,
  setSort,
  selectedItems,
  handleToggleAll,
  handleToggle,
}: NotificationsTableProps) => {
  const client = useQueryClient();
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const { t } = useTranslation();

  const { mutate: deleteNotification } = useMutation(
    async (id: string) => {
      const response = await instance.delete(`notification/${id}`);
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['notifications']);
        toastSuccess(t('notification_deleted'));
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead
            className={styles.tableCellHead}
            sortable={false}
            field="checkbox"
          >
            <CustomCheckbox
              isChecked={
                selectedItems.length === notifications.length &&
                !!notifications.length
              }
              onChange={handleToggleAll}
            />
          </TableCellHead>
          <TableCellHead field="module">{t('module')}</TableCellHead>
          <TableCellHead field="comment">{t('comment')}</TableCellHead>
          <TableCellHead field="status_id">{t('status')}</TableCellHead>
          <TableCellHead field="date">{t('send_date')}</TableCellHead>
          <TableCellHead sortable={false} field="actions">
            {t('action')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {notifications.map(
            ({ id, module_name, comment, status_id, date }: Notification) => (
              <TableRow key={id}>
                <TableCell>
                  <CustomCheckbox
                    isChecked={selectedItems.includes(id)}
                    onChange={() => handleToggle(id)}
                  />
                </TableCell>
                <TableCell>{module_name}</TableCell>
                <TableCell className={styles.commentCell}>
                  <TooltipText text={comment} />
                </TableCell>
                <TableCell>{renderStatus(status_id)}</TableCell>
                <TableCell>{dayjs(date).format('DD.MM.YYYY')}</TableCell>
                <TableCell>
                  <div className={styles.notificationsTableActions}>
                    <ModalConfirm
                      title={`${t('delete_notification_confirm')} «${comment}»`}
                      onConfirm={() => deleteNotification(id.toString())}
                    >
                      {open => (
                        <IconButton onClick={open}>
                          <Basket />
                        </IconButton>
                      )}
                    </ModalConfirm>
                    <IconButtonLink
                      to={`/notifications/${id}`}
                      className={styles.notificationsTableBtnLink}
                    >
                      <Edit />
                    </IconButtonLink>
                  </div>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};
