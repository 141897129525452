import { NavLink } from 'react-router-dom';
import useBreadcrumbs from './useBreadcrumbs';
import s from './Breadcrumbs.module.scss';
import loadRoutes from './routes';
import LoaderText from '../Shared/LoaderText/LoaderText';

const Breadcrumbs = ({ isLoading }: any) => {
  const breadcrumbs = useBreadcrumbs(loadRoutes());

  if (breadcrumbs.length < 2) return null;

  return (
    <ul className={s.container}>
      {breadcrumbs.map(({ path, title }, i) => {
        return (
          <li key={path}>
            {i === breadcrumbs.length - 1 ? (
              <LoaderText isLoading={isLoading} size={15}>
                {title}
              </LoaderText>
            ) : (
              <NavLink to={path}>{title}</NavLink>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumbs;
