import { useTranslation } from 'react-i18next';
import PaymentStatus from './PaymentStatus/PaymentStatus';
import CreditCardIcon from './images/credit-card.svg?react';
import EmployeeCardIcon from './images/user-card.svg?react';

import styles from './PaymentBlock.module.scss';

interface IPaymentBlockProps {
  type: string;
  status_type: string;
  name: string;
}

const PaymentBlock = ({ type, name, status_type }: IPaymentBlockProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.paymentType}>
      <label className={styles.label}>
        <span className={styles.labelText}>{t('payment_type')}</span>
        <div className={styles.paymentData}>
          {type === 'bank_card' ? (
            <CreditCardIcon className={styles.icon} />
          ) : (
            <EmployeeCardIcon className={styles.icon} />
          )}
          <span>{name}</span>
          {type === 'bank_card' && <PaymentStatus status={status_type} />}
        </div>
      </label>
    </div>
  );
};

export default PaymentBlock;
