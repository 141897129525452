import { useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { Board } from '~/components/Board/Board';
import { Button } from '~/ui';
import cn from 'classnames';
import styles from './QuestionsBoard.module.scss';
import { CloseQuestionForm } from './CloseQuestionForm/CloseQuestionForm';
import { OpenQuestionForm } from './OpenQuestionForm/OpenQuestionForm';
import { RaitingQuestionForm } from './RaitingQuestionForm/RaitingQuestionForm';
import { useTranslation } from 'react-i18next';

export const QuestionsBoard = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState('closed');
  const { t } = useTranslation();

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  };

  return (
    <Board classNames={styles.boardSection}>
      <div className={styles.boardTop}>
        <h3 className={styles.heading}>{t('new_question')}</h3>
        <Button
          onClick={() => {}}
          text={t('delete')}
          color="red"
          disabled={false}
          className={styles.closeBtn}
        />
      </div>
      <Tabs.Root
        className={styles.tabsRoot}
        value={activeTab}
        onValueChange={handleTabChange}
      >
        <div className={styles.tabsWrapper}>
          <Tabs.List
            className={styles.tabsList}
            aria-label="Manage your account"
          >
            <Tabs.Trigger
              value="closed"
              className={cn(styles.tabsTrigger, {
                [styles.tabsTriggerActive]: activeTab === 'closed',
              })}
            >
              {t('closed_question')}
            </Tabs.Trigger>
            <Tabs.Trigger
              value="open"
              className={cn(styles.tabsTrigger, {
                [styles.tabsTriggerActive]: activeTab === 'open',
              })}
            >
              {t('open_question')}
            </Tabs.Trigger>
            <Tabs.Trigger
              value="raiting"
              className={cn(styles.tabsTrigger, {
                [styles.tabsTriggerActive]: activeTab === 'raiting',
              })}
            >
              {t('estimate_question')}
            </Tabs.Trigger>
          </Tabs.List>
        </div>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="closed"
        >
          <CloseQuestionForm />
        </Tabs.Content>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="open"
        >
          <OpenQuestionForm />
        </Tabs.Content>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="raiting"
        >
          <RaitingQuestionForm />
        </Tabs.Content>
      </Tabs.Root>
    </Board>
  );
};
