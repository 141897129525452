import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { positionTitle } from '.';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButton, IconButtonLink } from '~/ui';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import styles from './PersonsFoodTable.module.scss';
import { useTranslation } from 'react-i18next';

interface FoodPersonItem {
  id: number;
  name: string;
  patronymic: string;
  email: string;
  phone: string;
  position: string;
}

interface Props {
  foodPersons: FoodPersonItem[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  foodMenuId: number;
}

export const PersonsFoodTable = ({
  foodPersons,
  sort,
  setSort,
  foodMenuId,
}: Props) => {
  const client = useQueryClient();
  const { t } = useTranslation();

  const { mutate: deletePerson } = useMutation(
    async (id: string) => {
      return await instance.delete(`admin/food-person/${id}`);
    },
    {
      onSuccess: () => {
        client.invalidateQueries([['food-persons'], foodMenuId]);
      },
    }
  );

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead sortable={false} field="name">
          {t('employee')}
        </TableCellHead>
        <TableCellHead sortable={false} field="position">
          {t('job_title')}
        </TableCellHead>
        <TableCellHead sortable={false} field="email">
          {t('mail')}
        </TableCellHead>
        <TableCellHead sortable={false} field="phone">
          {t('phone_number')}
        </TableCellHead>
        <TableCellHead sortable={false} field="actions">
          {t('action')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {foodPersons.map(
          ({
            id,
            name,
            patronymic,
            email,
            phone,
            position,
          }: FoodPersonItem) => (
            <TableRow key={id}>
              <TableCell>
                {name}&ensp;
                {patronymic}
              </TableCell>
              <TableCell>{positionTitle(position)}</TableCell>
              <TableCell>{email}</TableCell>
              <TableCell>{phone}</TableCell>
              <TableCell>
                <div className={styles.actions}>
                  <IconButton onClick={() => deletePerson(id.toString())}>
                    <Basket />
                  </IconButton>
                  <IconButtonLink
                    to={`/food/${foodMenuId}/persons/${id}`}
                    className={styles.btnLink}
                  >
                    <Edit />
                  </IconButtonLink>
                </div>
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};
