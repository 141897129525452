import { ChangeEvent, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useDebounce } from '@uidotdev/usehooks';
import { Board } from '~/components/Board/Board';
import { ErrorMessage } from '~/components/ErrorMessage/ErrorMessage';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { InputBar, Pagination, Spinner } from '~/ui';
import { FullMenuTable } from './FullMenuTable';
import '~/assets/css/select-table.css';
import styles from './FullMenuBoard.module.scss';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { useTranslation } from 'react-i18next';

interface SelectedPage {
  selected: number;
}

interface EditFoodMenuBoardProps {
  foodId: number;
  currentDate: string;
}
interface Points {
  page: number;
}

export const FullMenuBoard = ({
  foodId,
  currentDate,
}: EditFoodMenuBoardProps): JSX.Element => {
  const { initialValues, storedInitialValues, create } =
    useLocalStorage<Points>({
      initialValues: { page: 15 },
      key: 'dishes-points',
      exclude: [],
    });
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(
    storedInitialValues?.page || initialValues.page
  );
  const [page, setPage] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { t } = useTranslation();

  useEffect(() => {
    create({ page: perPage });
  }, [perPage]);

  const params = {
    sort: sort.direction,
    perPage,
    field: sort.field,
    query: debouncedSearchTerm,
    page,
    date: currentDate,
  };

  const {
    data: menu,
    isError,
    error,
  } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/ifcm-wares-all', {
        params,
      });
      return response.data;
    },

    queryKey: ['menu-dishes', foodId, params],
    keepPreviousData: true,
  });

  let content: React.ReactNode;

  if (menu?.data && foodId) {
    content = (
      <FullMenuTable
        menu={menu?.data}
        sort={sort}
        setSort={setSort}
        foodId={foodId}
      />
    );
  } else if (isError) {
    content = <ErrorMessage error={error} />;
  } else {
    content = <Spinner />;
  }

  return (
    <Board>
      <div className={styles.editFoodMenuBoardOptions}>
        <TableSelect perPage={perPage} setPerPage={setPerPage} />
        <InputBar
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          value={searchTerm}
          placeholder={t('search_by_name')}
          classNameInput={styles.editFoodMenuBoardInput}
          label={`${t('search')}:`}
          star={false}
          classNameInputWrapper={styles.editFoodMenuBoardInputWrapper}
        />
      </div>
      {content}
      <div className={styles.editFoodMenuBoardPagination}>
        <TableAmountPage
          firstRow={menu?.meta?.from}
          lastRow={menu?.meta?.to}
          total={menu?.meta?.total}
        />
        <Pagination
          pageCount={menu?.meta?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};
