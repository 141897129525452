import React from 'react';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import styles from './Page.module.scss';
import { Spinner } from '~/ui/Spinner/Spinner';

interface PageProps {
  heading: React.ReactNode;
  children: React.ReactNode;
  isError?: boolean;
  isLoading?: boolean;
}

const Page = ({ heading, children, isError, isLoading }: PageProps) => {
  const contentPage = () => {
    if (isError) {
      return <ErrorMessage />;
    } else if (isLoading) {
      return <Spinner />;
    } else {
      return children;
    }
  };

  return (
    <div id="page-container" className={styles.page}>
      <div className={styles.pageContainer}>
        <div className={styles.pageTop}>{heading}</div>
        <div className={styles.pageBottom}>{contentPage()}</div>
      </div>
    </div>
  );
};

export default Page;
