import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Heading from '~/components/Heading/Heading';
import styles from './FeedbackProccesing.module.scss';
import { instance } from '~/utils/api/api';
import { FeedbackProccesingForm } from './FeedbackProccesingForm';
import Page from '~/components/Page/Page';
import { Button } from '~/ui';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';

export interface FeedbackStatus {
  id: string | undefined;
  feedback_status: number;
}

export const FeedbackProccesing = () => {
  const { feedbackID } = useParams();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { data, isError, isLoading } = useQuery({
    queryFn: async () => {
      const response = await instance.get(`/admin/feedback/${feedbackID}`);
      return response.data;
    },
    queryKey: ['feedback', feedbackID],
  });

  const feedback = data?.feedback;

  const client = useQueryClient();

  const { mutate: closeFeedback } = useMutation(
    async ({ id, feedback_status }: FeedbackStatus) => {
      const response = await instance.patch(`/admin/feedback/${id}`, {
        feedback_status,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        toastSuccess(t('feedback_closed'));
        client.invalidateQueries(['feedback', feedbackID]);
        navigate('/feedback');
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={
            feedback?.created_at
              ? `${t('response')} ${dayjs(feedback?.created_at).format(
                  'DD.MM.YYYY'
                )}`
              : ''
          }
          classNameText={styles.pageHeading}
          actions={
            <Button
              disabled={false}
              color="red"
              text={t('feedback_close')}
              onClick={() =>
                closeFeedback({ id: feedbackID, feedback_status: 4 })
              }
            />
          }
        />
      }
      isError={isError}
      isLoading={isLoading}
    >
      <FeedbackProccesingForm feedback={feedback} />
    </Page>
  );
};

export default FeedbackProccesing;
