import cn from 'classnames';
import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import table from '~/theme/ui/table.module.scss';
import button from '~/theme/ui/button.module.scss';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import layout from '~/theme/ui/layout.module.scss';
import { useFormikContext } from 'formik';
import { getTypeOptions } from '../QuestionForm/typeOptions';
import ModalConfirm from '../ModalConfirm';
import { IconButton } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';

import styles from './QuestionsList.module.scss';
import { QuestionItem } from '../QuestionForm';
import { pluralize } from '~/utils/helpers';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

interface QuestionListProps {
  questions: QuestionItem[];
  clickAddQuestion: () => void;
  clickDeleteQuestion: (id: number) => void;
  clickEditButton: (id: number) => void;
}

const pluralizeVariant = (n: number) =>
  n +
  ' ' +
  pluralize(n, [
    i18next.t('question'),
    i18next.t('question_2'),
    i18next.t('question_3'),
  ]);

function QuestionsList({
  questions,
  clickAddQuestion,
  clickDeleteQuestion,
  clickEditButton,
}: QuestionListProps) {
  const { isSubmitting } = useFormikContext();
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const { t } = useTranslation();
  const typeOptions = getTypeOptions();

  const sortedQuestions =
    sort.direction === 'asc'
      ? questions.sort((a, b) => (a[sort.field] > b[sort.field] ? 1 : -1))
      : questions.sort((a, b) => (a[sort.field] < b[sort.field] ? 1 : -1));

  return (
    <div className={layout.block}>
      <div className={table.header}>
        <div className={table.header__title}>
          {t('questions_total')} {pluralizeVariant(questions.length)}
        </div>
        <button
          type="button"
          onClick={clickAddQuestion}
          className={cn(button.small, button.success)}
          disabled={isSubmitting}
        >
          <AddIcon className={button.icon} />
          {t('add_question')}
        </button>
      </div>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead field="name">{t('question_name')}</TableCellHead>
          <TableCellHead field="type">{t('question_type')}</TableCellHead>
          <TableCellHead sortable={false} field="end_date">
            {t('action')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {sortedQuestions.map(question => {
            return (
              <TableRow key={question.id}>
                <TableCell>{question.name}</TableCell>
                <TableCell>
                  {
                    typeOptions.find(
                      ({ typeName }) => typeName === question.type
                    )?.typeNamePlain
                  }
                </TableCell>
                <TableCell>
                  <div className={styles.actions}>
                    <ModalConfirm
                      title={`${t('delete_question_confirm')} «${
                        question.name
                      }»`}
                      onConfirm={() => clickDeleteQuestion(question.id)}
                    >
                      {open => (
                        <IconButton onClick={open}>
                          <Basket />
                        </IconButton>
                      )}
                    </ModalConfirm>
                    <IconButton
                      className={styles.action}
                      onClick={() => clickEditButton(question.id)}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {!questions.length && (
        <div className={table.noResult}>{t('create_new_question')}</div>
      )}
    </div>
  );
}

export default QuestionsList;
