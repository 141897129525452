import Page from '~/components/Page/Page';
import Heading from '~/components/Heading/Heading';
import { TransactionsBoard } from './TransactionsBoard';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { useContext } from 'react';
import { checkIfFoodAdmin } from '~/utils/getUserRole';
import { TransactionsFoodAdminBoard } from '../Transaction/TransactionsFoodAdminBoard/TransactionsFoodAdminBoard';

export const Transactions = (): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const isFoodAdminAccess = checkIfFoodAdmin(user?.data.role_name);
  return (
    <Page heading={<Heading text={t('orders')} />}>
      {isFoodAdminAccess ? (
        <TransactionsFoodAdminBoard />
      ) : (
        <TransactionsBoard />
      )}
    </Page>
  );
};
