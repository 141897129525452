import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import { ButtonLink } from '~/ui';
import { FoodBoard } from './FoodBoard';
import { useContext } from 'react';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { useTranslation } from 'react-i18next';

const Food = (): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const isCreateButtonShown = checkIfUserSuperAdmin(user?.data.role_name);
  const { t } = useTranslation();

  return (
    <Page
      heading={
        <Heading
          text={t('food_outlets')}
          actions={
            isCreateButtonShown && (
              <ButtonLink text={t('add_new_item')} to="/food/create" />
            )
          }
        />
      }
    >
      <FoodBoard />
    </Page>
  );
};

export default Food;
