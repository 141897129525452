import cn from 'classnames';
import styles from './NotificationsTable.module.scss';
import i18next from 'i18next';

export function renderGroup(group_send: string) {
  const t = i18next.t;
  if (group_send === '1') {
    return <span>{t('everyone')}</span>;
  } else if (group_send === '2') {
    return <span>{t('selective_recipients')}</span>;
  } else {
    return null;
  }
}

export function renderCondition(condition: string) {
  const t = i18next.t;
  if (condition === '1') {
    return <span>{t('date_and_time')}</span>;
  } else if (condition === '2') {
    return <span>{t('instantly')}</span>;
  } else if (condition === '3') {
    return <span>{t('script')}</span>;
  } else {
    return null;
  }
}

export function renderStatus(status_id: number) {
  const t = i18next.t;

  if (status_id === 2) {
    return (
      <span className={cn(styles.status, styles.statusGreen)}>
        {t('status_sent')}
      </span>
    );
  } else if (status_id === 1) {
    return (
      <span className={cn(styles.status, styles.statusOrange)}>
        {t('status_awaiting')}
      </span>
    );
  } else if (status_id === 3) {
    return (
      <span className={cn(styles.status, styles.statusRed)}>
        {t('status_archived')}
      </span>
    );
  } else {
    return null;
  }
}
