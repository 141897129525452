import { Select } from 'antd';
import React, { useState } from 'react';
import CloseSvg from '~/assets/svg/newSvg/close-purple.svg?react';
import '~/assets/css/select.css';
import styles from './MultipleSelect.module.scss';
import { useTranslation } from 'react-i18next';

export interface OptionItem {
  id: string;
  name: string;
  [key: string]: string;
}

interface SelectBarProps {
  label: string;
  options: OptionItem[];
  value: number | null | string | number[];
  onChange: (value: any) => void;
  star: boolean;
  el: string;
  disabled: boolean;
  mode?: 'multiple' | 'tags';
  secondEl?: string;
  valueItem?: string;
  name?: string;
}

export const MultipleSelect = ({
  label,
  options,
  value,
  onChange,
  star,
  el,
  disabled,
  mode,
  secondEl,
  valueItem,
}: SelectBarProps): JSX.Element => {
  const { Option } = Select;
  const [searchValue, setSearchValue] = useState('');
  const [isPlaceholderShown, setIsPlaceholderShown] = useState(true);
  const { t } = useTranslation();

  const filteredOptions = options?.filter(
    item => item[el]?.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className={styles.select}>
      <label className={styles.selectLabel}>
        <span className={styles.selectText}>
          {label}
          {star ? <span className={styles.selectStar}>*</span> : null}
        </span>
        <div className="selectBarWrapper">
          <Select
            disabled={disabled}
            value={value}
            onSelect={() => setSearchValue('')}
            onChange={onChange}
            onFocus={() => setIsPlaceholderShown(false)}
            onBlur={() => setIsPlaceholderShown(true)}
            className="ant-select"
            placeholder={isPlaceholderShown && t('to_choose')}
            mode={mode}
            filterOption={false}
            onSearch={(value: string) => {
              setSearchValue(value);
            }}
            maxTagCount="responsive"
            tagRender={props => {
              const { label, closable, onClose } = props;
              return (
                <span
                  className={styles.contentWrapper}
                  style={{ marginRight: 8 }}
                >
                  {label &&
                    Array.isArray(label) &&
                    label.map((item, index: number) => (
                      <React.Fragment key={index}>
                        <span className={styles.contentText}>
                          {item.props.children}
                        </span>
                      </React.Fragment>
                    ))}

                  {closable && (
                    <CloseSvg
                      onClick={e => {
                        e.stopPropagation();
                        onClose();
                      }}
                      className={styles.contentBtnClose}
                    />
                  )}
                </span>
              );
            }}
          >
            {filteredOptions?.map(item => (
              <Option
                key={item.id}
                value={valueItem === 'name' ? item.name : item.id}
              >
                <span className={styles.selectFirstText}>{item[el]}</span>
                {secondEl && (
                  <span className={styles.selectSecondText}>
                    {item[secondEl]}
                  </span>
                )}
              </Option>
            ))}
          </Select>
        </div>
      </label>
    </div>
  );
};
