import { ChangeEvent, useEffect, useState } from 'react';
import { Board } from '~/components/Board/Board';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { InputBar, Pagination } from '~/ui';
import { ArchiveTable } from './ArchiveTable';
import { socials } from '~/utils/socials';
import styles from './ArchiveBoard.module.scss';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { useTranslation } from 'react-i18next';

interface SelectedPage {
  selected: number;
}

interface Points {
  page: number;
}

export const ArchiveBoard = (): JSX.Element => {
  const { initialValues, storedInitialValues, create } =
    useLocalStorage<Points>({
      initialValues: { page: 15 },
      key: 'social-points',
      exclude: [],
    });
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(
    storedInitialValues?.page || initialValues.page
  );
  const [_, setPage] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    create({ page: perPage });
  }, [perPage]);

  let content: React.ReactNode;

  if (socials) {
    content = <ArchiveTable socials={socials} sort={sort} setSort={setSort} />;
  }

  return (
    <Board>
      <div className={styles.options}>
        <TableSelect perPage={perPage} setPerPage={setPerPage} />
        <InputBar
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          value={searchTerm}
          placeholder="Поиск..."
          classNameInput={styles.input}
          label={`${t('search')}:`}
          star={false}
          classNameInputWrapper={styles.inputWrapper}
        />
      </div>
      {content}
      <div className={styles.pagination}>
        <TableAmountPage firstRow={1} lastRow={2} total={2} />
        <Pagination
          pageCount={1}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
    </Board>
  );
};
