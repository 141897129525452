import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { getLocalTimeDate } from '~/utils/getLocalTimeDate';
import moment from 'moment-timezone';
import { Button, InputBar, Textarea, GhostPicture } from '~/ui/index';
import UploadPhotoItem from '~/components/UploadPhoto/UploadPhotoItem/UploadPhotoItem';
import styles from './RequestForm.module.scss';
import { VideoPlayer } from '~/components/VideoPlayer/VideoPlayer';
import { useTranslation } from 'react-i18next';

export interface Photo {
  id: number;
  full_photo_url: string;
}

interface Video {
  id: number;
  full_video_url: string;
  full_preview_video_url: string;
}

export interface RequestFormItem {
  category_name: string;
  object_name: string;
  description: string;
  ticket_status: number;
  user_name: string;
  grade_name: string;
  created_at: string;
  user_uniqueId: string;
  ticket_photo: Photo[];
  ticket_video: Video[];
}

interface RequestFormProps {
  request: RequestFormItem;
}

export const RequestForm = ({ request }: RequestFormProps) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { t } = useTranslation();

  const photos = request?.ticket_photo;

  const formik = useFormik({
    initialValues: {
      category: '',
      object_name: '',
      comments: '',
      status: '',
      user_name: '',
      grade: '',
      date: '',
      uniqueID: '',
    },

    onSubmit: () => {
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (!request) return;

    formik.setValues({
      category: request.category_name,
      object_name: request.object_name,
      comments: request.description,
      status: request.ticket_status.toString(),
      user_name: request.user_name,
      grade: request.grade_name,
      date: getLocalTimeDate(moment(request.created_at).format()),
      uniqueID: request.user_uniqueId,
    });
  }, [request]);

  return (
    <div className={styles.formWrapper}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formItems}>
          <InputBar
            name="category"
            value={formik.values.category || t('not_specifiedA')}
            disabled={true}
            label={t('category')}
          />

          <InputBar
            name="object"
            value={formik.values.object_name || t('not_specified')}
            disabled={true}
            label={t('object_place')}
          />

          <InputBar
            name="uniqueID"
            value={formik.values.uniqueID}
            disabled={true}
            label={t('unique_user_ID')}
          />

          <div className={styles.infoWrapper}>
            <InputBar
              name="user_name"
              value={formik.values.user_name}
              disabled={true}
              label={t('employee')}
            />

            <InputBar
              name="grade"
              value={formik.values.grade}
              disabled={true}
              label={t('access_group')}
            />

            <InputBar
              name="date"
              value={formik.values.date}
              disabled={true}
              label={t('date_and_time')}
            />
          </div>

          <Textarea
            showCounter
            rows={6}
            label={t('description')}
            placeholder={t('leave_comment')}
            maxLength={1000}
            value={formik.values.comments}
            onChange={formik.handleChange}
            name="comments"
            star={false}
            disabled={true}
          />

          <div className={styles.mediaContainer}>
            <div className={styles.imgContainer}>
              {photos?.length === 0 && <GhostPicture />}
              {photos?.map(({ id, full_photo_url }) => {
                return (
                  <UploadPhotoItem
                    key={id}
                    url={full_photo_url}
                    deletePhoto={false}
                  />
                );
              })}
            </div>
            {request
              ? request.ticket_video.map(
                  ({ id, full_video_url, full_preview_video_url }) => (
                    <VideoPlayer
                      key={id}
                      url={full_video_url}
                      poster={full_preview_video_url}
                    />
                  )
                )
              : null}
          </div>
        </div>

        <Button
          type="submit"
          text={t('back_to_list')}
          className={styles.btn}
          onClick={goBack}
          disabled={false}
        />
      </form>
    </div>
  );
};
