import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import {
  FoodPersonForm,
  FoodPersonFormValues,
} from '~/components/FoodPersonForm/FoodPersonForm';
import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import styles from './CreateFoodPerson.module.scss';

const CreateFoodPerson = () => {
  const { foodID } = useParams();
  const navigate = useNavigate();
  const toastError = useToastError();

  const client = useQueryClient();

  const { mutateAsync: createFoodPerson } = useMutation(
    async ({
      name,
      patronymic,
      position,
      email,
      phone,
    }: FoodPersonFormValues) => {
      const response = await instance.post('admin/food-person', {
        name,
        patronymic,
        position,
        email,
        phone,
        food_id: foodID,
      });
      return response.data;
    },
    {
      onSuccess: (data: FoodPersonFormValues) => {
        client.setQueryData(
          ['food'],
          (oldFoodPerson?: FoodPersonFormValues[]) => {
            if (!oldFoodPerson) {
              return [data];
            }
            return [...oldFoodPerson, data];
          }
        );
        navigate(`/food/${foodID}`);
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text="Создание ответственного лица"
          classNameText={styles.title}
        />
      }
    >
      <FoodPersonForm
        onSubmit={values => createFoodPerson(values)}
        storageKey="create-food-person"
      />
    </Page>
  );
};

export default CreateFoodPerson;
