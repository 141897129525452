import { useLocalStorage } from '~/utils/useLocalStorage';
import styles from './CreateQrFrom.module.scss';
import { useFormik } from 'formik';
import { useToastError } from '~/utils/useToastError';
import { Button, SelectBar } from '~/ui';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useTranslation } from 'react-i18next';

interface QrModule {
  id: number;
  name: string;
  type: string;
}
export interface CreateQrValues {
  type: string;
  name: string;
  qr_code_module_id: number | null;
  object_id: number | null;
}

interface Props {
  storageKey: string;
  onSubmit: (values: CreateQrValues) => void;
}

const CreateQrFrom = ({ storageKey, onSubmit }: Props): JSX.Element => {
  const toastError = useToastError();
  const { t } = useTranslation();

  const params = {
    sort: '',
    perPage: 100,
    field: '',
    query: '',
    page: 1,
  };

  const { data: qrModules } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/qr-modules');
      return response.data;
    },
    queryKey: ['qr-modules'],
    onError: () => {
      toastError('Не удалось загрузить объекты');
    },
  });

  const { data: accommodations } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/accommodations', { params });
      return response.data;
    },
    queryKey: ['accommodations', params],
    onError: () => {
      toastError('Не удалось загрузить объекты размещения!');
    },
  });

  const { data } = useQuery({
    queryFn: async () => {
      const response = await instance.get('food-admin', { params });
      return response.data;
    },
    queryKey: ['food', params],
  });

  const { data: questionnaires } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/questionnaires');
      return response.data;
    },
    queryKey: ['questionnaires'],
  });

  const foods = data?.data;

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<CreateQrValues>({
      initialValues: {
        type: 'questionnaire',
        name: t('to_choose'),
        qr_code_module_id: null,
        object_id: null,
      },
      key: storageKey && `${storageKey}-v1`,
      exclude: [],
    });

  const formik = useFormik<CreateQrValues>({
    initialValues: storedInitialValues,

    onSubmit: async (values: CreateQrValues) => {
      try {
        await onSubmit(values);
        clear();
        formik.resetForm({ values: initialValues });
      } catch (error) {
        toastError(error);
      }
    },
  });

  create(formik.values);

  let content;

  if (formik.values.type === 'accommodation') {
    content = (
      <SelectBar<number | null>
        label={t('object')}
        options={accommodations?.data}
        value={formik.values.object_id}
        onChange={value => {
          formik.setFieldValue('object_id', value);
        }}
        star={false}
        name="object_id"
        el="name"
        disabled={false}
      />
    );
  } else if (formik.values.type === 'food') {
    content = (
      <SelectBar<number | null>
        label={t('object')}
        options={foods}
        value={formik.values.object_id}
        onChange={value => {
          formik.setFieldValue('object_id', value);
        }}
        star={false}
        name="object_id"
        el="name"
        disabled={false}
      />
    );
  } else if (formik.values.type === 'questionnaire') {
    content = (
      <SelectBar<number | null>
        label={t('object')}
        options={questionnaires?.data}
        value={formik.values.object_id}
        onChange={value => {
          formik.setFieldValue('object_id', value);
        }}
        star={false}
        name="object_id"
        el="name"
        disabled={false}
      />
    );
  }

  const getName = (id: string) => {
    const objectName = qrModules?.find((el: QrModule) => el.id === Number(id));
    return objectName.name;
  };

  const getType = (id: string) => {
    const objectName = qrModules?.find((el: QrModule) => el.id === Number(id));
    return objectName.type;
  };

  return (
    <form
      className={styles.form}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <SelectBar<string>
        label={t('module')}
        options={qrModules}
        value={formik.values.name}
        onChange={value => {
          formik.setFieldValue('qr_code_module_id', Number(value));
          formik.setFieldValue('type', getType(value));
          formik.setFieldValue('name', getName(value));
          formik.setFieldValue('object_id', null);
        }}
        star={false}
        name="name"
        el="name"
        disabled={false}
        placeholder={t('choose_module')}
      />
      {formik.values.qr_code_module_id && content}
      <Button
        type="submit"
        text={t('generate_qr')}
        className={styles.formBtn}
        disabled={
          !formik.values.object_id ||
          !formik.values.qr_code_module_id ||
          formik.isSubmitting
        }
      />
    </form>
  );
};

export default CreateQrFrom;
