import { useState } from 'react';
import { Board } from '~/components/Board/Board';
import styles from './BusinessTripHistoryTable.module.scss';
import BusinessTripTable from './BusinessTripTable/BusinessTripTable';
import { AccommodationItem } from '~/components/AccommodationForm';
import { FormikValues } from 'formik';
import { Action } from '~/ui/Actions';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { useTranslation } from 'react-i18next';

interface IBusinessTripHistoryTable {
  history: [];
  formik: FormikValues;
  isEditing: boolean | undefined;
  accommodationParams: any;
  accommodations: AccommodationItem[];
}

const BusinessTripHistoryTable: React.FC<IBusinessTripHistoryTable> = ({
  history = [],
  formik,
  isEditing,
  accommodationParams,
  accommodations,
}) => {
  const [isCreateNewRecord, setIsCreateNewRecord] = useState<boolean>(false);
  const [editRowId, setEditRowId] = useState<number | null>(null);
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const { t } = useTranslation();

  let content: React.ReactNode;

  const openCreateNewTrip = () => {
    setIsCreateNewRecord(true);
  };

  const closeCreateTrip = () => {
    setIsCreateNewRecord(false);
  };

  if (history) {
    content = (
      <BusinessTripTable
        accommodations={accommodations}
        isEditing={isEditing}
        editRowId={editRowId}
        setEditRowId={setEditRowId}
        formik={formik}
        isCreateShown={isCreateNewRecord}
        sort={sort}
        setSort={setSort}
        businessTrips={history}
        params={accommodationParams}
        closeCreateTrip={closeCreateTrip}
      />
    );
  }

  return (
    <Board>
      <div className={styles.businessTripTableOptions}>
        <Action variant="success" onClick={() => openCreateNewTrip()}>
          <AddIcon /> {t('create_new_business_trip')}
        </Action>
      </div>
      {content}
    </Board>
  );
};

export default BusinessTripHistoryTable;
