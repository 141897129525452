import TransactionDishesTable from './TransactionDishesTable/TransactionDishesTable';
import { ITransaction } from '../TransactionsFoodAdminBoard';
import { useTranslation } from 'react-i18next';
import Tooltip from '~/components/Shared/Tooltip/Tooltip';
import { Button, BUTTON_STYLES, IconButton, Spinner } from '~/ui';
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useToastError } from '~/utils/useToastError';
import ModalConfirm from '~/components/ModalConfirm';
import CheckIcon from './images/check.svg?react';

import styles from './TransactionsFoodAdminModal.module.scss';
import FoodAdminAction from './FoodAdminAction/FoodAdminAction';

const TransactionsFoodAdminModal = ({
  transaction,
  isTransactionsLoading,
  onCloseClick,
}: {
  transaction: ITransaction;
  isTransactionsLoading: boolean;
  onCloseClick: () => void;
}) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const client = useQueryClient();

  const { t } = useTranslation();

  const handleCancelDishes = async () => {
    setIsLoading(true);
    try {
      const { data } = await instance.delete(`admin/food-order-wares`, {
        data: {
          orders_data: [
            {
              order_id: transaction?.id,
              order_ware_ids: selectedItems,
            },
          ],
        },
      });
      toastSuccess(data?.message || t('cancel_dish'));
      client.invalidateQueries(['transactions-food-point']);
      setSelectedItems([]);
    } catch (error: any) {
      toastError(error?.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const { mutate: deleteOrder } = useMutation(
    async (id: number) => {
      setIsLoading(true);
      if (id) {
        await instance.delete(`admin/food-orders/${id}`);
      }
    },
    {
      onSuccess: () => {
        setIsLoading(false);
        client.invalidateQueries(['transactions']);
        toastSuccess(t('order_deleted'));
        onCloseClick();
      },
      onError: (error: any) => {
        setIsLoading(false);
        toastError(error?.response.data.error);
      },
    }
  );

  const { mutate: approveOrder } = useMutation(
    async () => {
      setIsLoading(true);
      if (transaction?.id) {
        await instance.post(`admin/food-orders-confirm/${transaction?.id}`);
      }
    },
    {
      onSuccess: () => {
        setIsLoading(false);
        client.invalidateQueries(['transactions-food-point']);
        toastSuccess(t('order_confirmed'));
        onCloseClick();
      },
      onError: (error: any) => {
        setIsLoading(false);
        toastError(error?.response.data.error);
      },
    }
  );

  const handleSelect = (id: number) => {
    if (selectedItems.includes(id)) {
      const filteredFoods = selectedItems.filter(
        selectedId => selectedId !== id
      );
      setSelectedItems(filteredFoods);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedItems.length === transaction?.food_wares?.length) {
      setSelectedItems([]);
    } else {
      const foodIdArray = transaction?.food_wares?.map((transaction: any) => {
        if (!transaction?.is_cancel) {
          return transaction?.id;
        }
      });
      setSelectedItems(foodIdArray);
    }
  };

  const isDisabled = !transaction?.can_cancel;

  return (
    <div className={styles.transactionsFoodAdminModal}>
      <div className={styles.header}>
        <span className={styles.title}>
          {t('order')} {transaction.id} | {transaction.user_name}
        </span>
        <div className={styles.headerRight}>
          {!!selectedItems.length && (
            <div className={styles.cancelButtonWrapper}>
              <ModalConfirm
                title={`${t('cancel_dishes_confirmation')}`}
                onConfirm={handleCancelDishes}
              >
                {open => (
                  <Button
                    type="button"
                    text={t('cancel_selected_dishes')}
                    buttonStyle={BUTTON_STYLES.EMPTY}
                    onClick={open}
                    disabled={isLoading}
                    className={styles.deleteButton}
                  />
                )}
              </ModalConfirm>
            </div>
          )}
          {transaction?.status_type !== 'canceled' ? (
            <>
              {isDisabled ? (
                <div className={styles.cancelButtonWrapper}>
                  <Tooltip
                    tooltipStyle={styles.transactionTooltip}
                    title={t('canceled_tooltip')}
                    position="left"
                  >
                    <Button
                      color="red"
                      text={t('cancel_order')}
                      onClick={() => deleteOrder(transaction?.id)}
                      disabled={isDisabled}
                      className={styles.deleteButton}
                    />
                  </Tooltip>
                </div>
              ) : (
                <div className={styles.cancelButtonWrapper}>
                  <ModalConfirm
                    title={`${t(
                      'cancel_order_confirmation'
                    )} №${transaction?.id}?`}
                    onConfirm={() => deleteOrder(transaction?.id)}
                  >
                    {open => (
                      <Button
                        color="red"
                        text={t('cancel_order')}
                        onClick={open}
                        disabled={isLoading}
                        className={styles.deleteButton}
                      />
                    )}
                  </ModalConfirm>
                </div>
              )}
            </>
          ) : (
            ''
          )}
          <FoodAdminAction id={transaction?.id} />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.button__wrapper}>
          <span className={styles.tableTitle}>{t('compound')}</span>
        </div>
        <div className={styles.table}>
          <TransactionDishesTable
            isAllCancelled={transaction?.status_type === 'canceled'}
            selectedItems={selectedItems}
            onCheckboxPress={handleSelect}
            onCheckboxHeadPress={handleSelectAll}
            order={transaction.food_wares}
            isRowClickable
          />
          {isLoading || isTransactionsLoading ? (
            <div className={styles.loader}>
              <Spinner />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className={styles.controls}>
        <Button
          text={t('close')}
          className={styles.cancelButton}
          disabled={isLoading}
          buttonStyle={BUTTON_STYLES.EMPTY}
          onClick={onCloseClick}
        />
        <IconButton
          isDisabled={isLoading || transaction?.status_type === 'canceled'}
          onClick={approveOrder}
          className={styles.approveButton}
        >
          <CheckIcon />
          {t('approve_order')}
        </IconButton>
      </div>
    </div>
  );
};

export default TransactionsFoodAdminModal;
