import React, { useEffect, useState } from 'react';
import ModalConfirm from '~/components/ModalConfirm';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import { IconButton, Select } from '~/ui';
import styles from './BusinessTripTable.module.scss';
import { DatePicker } from 'antd';
import DatePickerIcon from '~/assets/svg/datePicker/datePickerIcon.svg?react';
import CheckMarkIcon from '~/assets/svg/newSvg/mark-icon.svg?react';
import CloseMarkIcon from '~/assets/svg/newSvg/basket.svg?react';
import { ParamsProps, getRooms } from '~/utils/api/queries';
import { AccommodationItem } from '~/components/AccommodationForm';
import { instance } from '~/utils/api/api';
import { useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { FormikValues } from 'formik';
import { IUserBusinessTrip } from '../../UserForm';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useToastError } from '~/utils/useToastError';
import EditRow from './EditRow/EditRow';
import { useTranslation } from 'react-i18next';
import { IRoom } from '../../BusinessTripForm';

interface IBusinessTripTableProps {
  accommodations: AccommodationItem[];
  editRowId: number | null;
  setEditRowId: (id: number | null) => void;
  formik: FormikValues;
  isCreateShown: boolean;
  businessTrips: IUserBusinessTrip[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  params: ParamsProps;
  closeCreateTrip: () => void;
  isEditing: boolean | undefined;
}

interface IBusinessTrip {
  id: number;
  user_id: number;
  start_date: string;
  end_date: string;
  accommodation_id: number;
  created_at: string;
  updated_at: string;
  accommodation: AccommodationItem;
}

const BusinessTripTable: React.FC<IBusinessTripTableProps> = ({
  accommodations,
  editRowId,
  setEditRowId,
  formik,
  isCreateShown,
  businessTrips,
  sort,
  setSort,
  params,
  closeCreateTrip,
  isEditing,
}) => {
  const { userID } = useParams();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [accomodationId, setAccomodationId] = useState<number | null>(null);
  const [roomId, setRoomId] = useState<number | null>(null);
  const [rooms, setRooms] = useState<any>([]);
  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const { t } = useTranslation();

  const loadRooms = async () => {
    if (accomodationId) {
      const data = await getRooms(accomodationId);
      setRooms(data);
    }
  };

  const accommodationsOptions = accommodations.map(item => {
    return { id: item.id, name: item.full_name };
  });

  function getAccommodation(id: number) {
    const item = accommodations.find(item => item.id === id);

    return item ? item.name + '/' + item.section : '';
  }

  function getRoom(id: number) {
    const item = rooms.find((room: IRoom) => room.id === id);

    return item ? item.name : '';
  }

  const clearValues = () => {
    setStartDate(null);
    setEndDate(null);
    setAccomodationId(null);
  };

  const setRowEdit = (id: number) => {
    setEditRowId(id);
    clearValues();
    closeCreateTrip();
  };

  const closeEditing = () => {
    setEditRowId(null);
  };

  const closeTripEdit = () => {
    setStartDate(null);
    setEndDate(null);
    closeCreateTrip();
  };

  const saveNewTrip = async () => {
    try {
      const { data } = await instance.post('admin/business-trip', [
        {
          accommodation_id: accomodationId,
          start_date: dayjs(startDate).format('YYYY-MM-DD'),
          end_date: dayjs(endDate).format('YYYY-MM-DD'),
          user_id: userID,
          room_id: roomId,
        },
      ]);
      formik.setFieldValue('business_trips', [
        ...formik.values.business_trips,
        ...data,
      ]);
      closeCreateTrip();
      toastSuccess(`${t('new_business_trip_added_successfully')}!`);
      clearValues();
    } catch (error) {
      toastError(t('error_adding_new_business_trip'));
    }
  };

  const updateTrip = async (id: number) => {
    try {
      const { data } = await instance.put(`admin/business-trip/${id}`, {
        accommodation_id: accomodationId,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        room_id: roomId,
      });

      formik.setFieldValue('business_trips', [
        ...formik.values.business_trips.filter(
          (trip: IBusinessTrip) => trip.id !== id
        ),
        data,
      ]);

      toastSuccess(`${t('business_trip_successfully_edited')}!`);
      setEditRowId(null);
    } catch (error) {
      toastError(t('error_editing_business_trip'));
    }
  };

  const saveTripToForm = () => {
    accommodations.find(accomodation => {
      return accomodation.id === accomodationId;
    });
    formik.setFieldValue('business_trips', [
      ...formik.values.business_trips,
      {
        id: formik.values.business_trips.length + 1,
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
        accommodation: accommodations.find(
          accomodation => accomodation.id === accomodationId
        ),
        room_id: roomId,
        room: rooms.find((room: IRoom) => room.id === roomId),
      },
    ]);
    closeTripEdit();
  };

  const deleteTripFromForm = (id: number) => {
    formik.setFieldValue(
      'business_trips',
      formik.values.business_trips.filter(
        (trip: IBusinessTrip) => id !== trip.id
      )
    );
  };

  const deleteTrip = async (id: number) => {
    const initialTrips = formik.values.business_trips;
    formik.setFieldValue(
      'business_trips',
      formik.values.business_trips.filter(
        (trip: IBusinessTrip) => id !== trip.id
      )
    );
    try {
      await instance.delete(`admin/business-trip/${id}`);
      toastSuccess(`${t('business_trip_successfully_deleted')}!`);
    } catch (error) {
      formik.setFieldValue('business_trips', initialTrips);
      toastError(t('error_deleting_business_trip'));
    }
  };

  useEffect(() => {
    loadRooms();
  }, [accomodationId]);

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead sortable={false} field="start_date">
          {t('business_trip_start_date')}
        </TableCellHead>
        <TableCellHead sortable={false} field="end_date">
          {t('business_trip_end_date')}
        </TableCellHead>
        <TableCellHead sortable={false} field="object">
          {t('accommodation_facility_section')}
        </TableCellHead>
        <TableCellHead sortable={false} field="object">
          {t('room')}
        </TableCellHead>
        <TableCellHead sortable={false} field="actions">
          {t('action')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {businessTrips.map(
          ({ id, room, start_date, end_date, accommodation }: any) => {
            if (editRowId === id) {
              return (
                <EditRow
                  id={id}
                  startDateToEdit={start_date}
                  endDateToEdit={end_date}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  accommodationToEdit={accommodation}
                  accommodationId={accomodationId}
                  roomId={roomId}
                  rooms={rooms}
                  getRoom={getRoom}
                  setRoomId={setRoomId}
                  getAccommodation={getAccommodation}
                  setAccomodation={setAccomodationId}
                  params={params}
                  updateTrip={updateTrip}
                  clickStopEditing={closeEditing}
                />
              );
            } else {
              return (
                <TableRow key={id}>
                  <TableCell>
                    {dayjs(start_date).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>{dayjs(end_date).format('DD.MM.YYYY')}</TableCell>
                  <TableCell>
                    {accommodation?.name}/{accommodation?.section}
                  </TableCell>
                  <TableCell className={styles.roomCell}>
                    {room?.name || t('not_available')}
                  </TableCell>
                  <TableCell>
                    <div className={styles.BusinessTripTableActions}>
                      <ModalConfirm
                        title={`${t('sure_to_delete_business_trip')}?`}
                        onConfirm={() => {
                          isEditing ? deleteTrip(id) : deleteTripFromForm(id);
                        }}
                      >
                        {open => (
                          <IconButton onClick={open}>
                            <Basket />
                          </IconButton>
                        )}
                      </ModalConfirm>
                      {isEditing ? (
                        <IconButton
                          onClick={() => {
                            setRowEdit(id);
                            setRoomId(room.id);
                          }}
                          className={styles.BusinessTripTableBtnLink}
                        >
                          <Edit />
                        </IconButton>
                      ) : (
                        ''
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
          }
        )}
        {isCreateShown && (
          <TableRow>
            <TableCell>
              <DatePicker
                className={styles.BusinessTripDatePicker}
                allowClear={false}
                suffixIcon={<DatePickerIcon />}
                disabledDate={d => !d || d.isAfter(endDate)}
                format="DD.MM.YYYY"
                placeholder={t('date')}
                value={startDate}
                onChange={date => {
                  setStartDate(date);
                }}
              />
            </TableCell>
            <TableCell>
              <DatePicker
                className={styles.BusinessTripDatePicker}
                allowClear={false}
                suffixIcon={<DatePickerIcon />}
                disabledDate={d => !d || d.isBefore(startDate)}
                format="DD.MM.YYYY"
                placeholder={t('date')}
                value={endDate}
                onChange={date => {
                  setEndDate(date);
                }}
              />
            </TableCell>
            <TableCell>
              <Select
                onChange={value => {
                  setAccomodationId(value);
                }}
                value={accomodationId}
                size="small"
                options={accommodationsOptions}
                star={true}
                getValueText={getAccommodation}
              />
            </TableCell>
            <TableCell>
              <Select
                onChange={value => {
                  setRoomId(value);
                }}
                value={roomId}
                size="small"
                options={rooms}
                star={true}
                getValueText={getRoom}
              />
            </TableCell>
            <TableCell>
              <div className={styles.BusinessTripTableActions}>
                <IconButton onClick={isEditing ? saveNewTrip : saveTripToForm}>
                  <CheckMarkIcon className={styles.BusinessTripSaveIcon} />
                </IconButton>
                <IconButton onClick={closeTripEdit}>
                  <CloseMarkIcon className={styles.BusinessTripSaveIcon} />
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default BusinessTripTable;
