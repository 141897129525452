import styles from './LoaderText.module.scss';

interface ILoaderTextProps {
  text?: string;
  size?: number;
  children?: React.ReactNode;
  isLoading: boolean;
}

const LoaderText = ({
  text = 'ObjectTest',
  size = 38,
  children,
  isLoading,
}: ILoaderTextProps) => {
  return (
    <div
      style={isLoading ? { opacity: 0 } : { opacity: 1 }}
      className={styles.loaderText}
    >
      {isLoading ? (
        <span style={{ fontSize: size }} className={styles.loaderTextSpan}>
          {text}
        </span>
      ) : (
        children
      )}
    </div>
  );
};

export default LoaderText;
