import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import {
  FoodPersonForm,
  FoodPersonFormValues,
} from '~/components/FoodPersonForm/FoodPersonForm';
import { Button } from '~/ui';
import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import styles from './EditFoodPerson.module.scss';
import { useTranslation } from 'react-i18next';

const EditFoodPerson = () => {
  const { foodID, personID } = useParams();
  const navigate = useNavigate();
  const toastError = useToastError();
  const { t } = useTranslation();

  const client = useQueryClient();

  const { data: foodPersonId } = useQuery({
    queryFn: async () => {
      const response = await instance.get(`admin/food-person/${personID}`);
      return response.data;
    },
    queryKey: ['food-perdon-id', personID],
    onError: error => {
      toastError(error);
    },
  });

  const { mutate: deletePerson } = useMutation(
    async (id: string) => {
      return await instance.delete(`admin/food-person/${id}`);
    },
    {
      onSuccess: () => {
        client.invalidateQueries([['food-persons'], foodID]);
        navigate(`/food/${foodID}`);
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const { mutateAsync: editFoodPerson } = useMutation(
    async ({
      name,
      patronymic,
      position,
      email,
      phone,
    }: FoodPersonFormValues) => {
      const response = await instance.post(
        `admin/food-person/${personID}`,
        {
          name,
          patronymic,
          position,
          email,
          phone,
          food_id: foodID,
        },
        {
          params: {
            _method: 'patch',
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data: FoodPersonFormValues) => {
        client.setQueryData(
          ['food'],
          (oldFoodPerson?: FoodPersonFormValues[]) => {
            if (!oldFoodPerson) {
              return [data];
            }
            return [...oldFoodPerson, data];
          }
        );
        navigate(`/food/${foodID}`);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={t('editing_person')}
          actions={
            <Button
              text={t('remove_person')}
              disabled={false}
              color="red"
              onClick={() => deletePerson(personID as string)}
            />
          }
          classNameText={styles.title}
        />
      }
    >
      <FoodPersonForm
        onSubmit={values => editFoodPerson(values)}
        foodPersonItem={foodPersonId?.data}
      />
    </Page>
  );
};

export default EditFoodPerson;
