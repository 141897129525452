import styles from './CustomCheckbox.module.scss';
import Checkmark from './images/check.svg?react';
import cn from 'classnames';

interface ICustomCheckboxProps {
  isChecked: boolean;
  onChange: () => void;
}

const CustomCheckbox = ({ isChecked, onChange }: ICustomCheckboxProps) => {
  return (
    <div className={styles.checkboxContainer}>
      <input
        id="custom_checkbox"
        name="custom_checkbox"
        onChange={onChange}
        className={styles.input}
        type="checkbox"
        checked={isChecked}
      />
      <label
        htmlFor="custom_checkbox"
        className={cn(
          styles.customCheckbox,
          isChecked && styles.customCheckboxChecked
        )}
      >
        {isChecked && <Checkmark className={styles.checkmark} />}
      </label>
    </div>
  );
};

export default CustomCheckbox;
