import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { DatePicker, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { BUTTON_STYLES, Button, RadioInput, Select } from '~/ui';
import styles from './XlsReportFeedbacksPopoverContent.module.scss';
import { useContext, useState } from 'react';
import { PopoverContext } from '~/components/XlsUploadFilePopover/XlsUploadFilePopover';
import { downloadReportFile } from '~/utils/downloadReportFile';
import { useDebounce } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { AccommodationItem } from '~/components/AccommodationForm';

const XlsReportFeedbacksPopoverContent = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [accommodationIds, setAccommodationIds] = useState<number[]>([]);
  const [typeReport, setTypeReport] = useState('1');
  const debouncedSearchTerm = useDebounce('', 500);
  const toastError = useToastError();
  const { setIsPopoverVisible } = useContext(PopoverContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const params = {
    sort: '',
    perPage: 100,
    field: '',
    query: debouncedSearchTerm,
    page: 1,
  };

  const onClosePopup = () => {
    setStartDate(null);
    setEndDate(null);
    setIsPopoverVisible(false);
    setAccommodationIds([]);
  };

  const { data: accommodations } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/accommodations', { params });
      return response.data;
    },
    queryKey: ['accommodations', params],
    onError: () => {
      toastError(t('xls_download_error'));
    },
  });

  const downloadFileReport = async () => {
    setIsLoading(true);
    let typeReportPath: string;
    let typeReportName: string;

    if (typeReport === '1') {
      typeReportPath = `/analytics-feedback-accommodation-date${
        accommodationIds.length
          ? `?accommodation_ids[]=${accommodationIds.join(
              '&accommodation_ids[]='
            )}`
          : ''
      }`;
      typeReportName = 'analytics-accommodation-feedback';
    } else {
      return;
    }

    await downloadReportFile(
      typeReportPath,
      typeReportName,
      startDate,
      endDate
    );
    setIsLoading(false);
  };

  const disabledSendReport = !startDate || !endDate;

  const disabledDate = (current: Dayjs) => {
    if (!startDate) {
      return current > dayjs().startOf('day');
    }

    return current < dayjs(startDate).startOf('day');
  };

  function getAccommodation(id: number) {
    const item = accommodations?.data.find(
      (item: AccommodationItem) => item.id === id
    );

    return item ? item.name + '/' + item.section : '';
  }

  return (
    <div className={styles.formWrapper}>
      <span className={styles.formTitle}>{t('feedback_analytics')}</span>
      <div className={styles.radioBtnTypeReportWrapper}>
        <RadioInput
          label={t('accommodations_report')}
          name="type_report"
          value="1"
          onChange={() => setTypeReport('1')}
          checkValue={typeReport}
          disabled={false}
        />
      </div>
      <Select
        label={t('object')}
        options={accommodations?.data}
        value={accommodationIds}
        onChange={value => {
          setAccommodationIds(value);
        }}
        star={true}
        disabled={false}
        search={false}
        className={styles.selectXls}
        size="small"
        labelStyle={styles.selectObjectLabelStyle}
        getValueText={getAccommodation}
        multiple
      />
      <div className={styles.dateWrapper}>
        <div className={styles.labelWrapper}>
          <span className={styles.labelText}>
            {t('date_from')}
            <span className={styles.labelStar}>*</span>
          </span>
          <Space direction="vertical" className="ant-space">
            <DatePicker
              className="datepicker"
              format="DD.MM.YYYY"
              value={startDate ? dayjs(startDate) : null}
              onChange={date => {
                setStartDate(date ? date : null);
                setEndDate(null);
              }}
              placeholder={t('date')}
              disabledDate={disabledDate}
            />
          </Space>
        </div>
        <div className={styles.labelWrapper}>
          <span className={styles.labelText}>
            {t('date_by')}
            <span className={styles.labelStar}>*</span>
          </span>
          <Space direction="vertical" className="ant-space">
            <DatePicker
              className="datepicker"
              format="DD.MM.YYYY"
              value={endDate ? dayjs(endDate) : null}
              onChange={date => {
                setEndDate(date ? date : null);
              }}
              placeholder={t('date')}
              disabledDate={disabledDate}
            />
          </Space>
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <Button
          onClick={onClosePopup}
          className={styles.contentBtn}
          text={t('cancel')}
          disabled={false}
          buttonStyle={BUTTON_STYLES.EMPTY}
        />
        <Button
          onClick={downloadFileReport}
          type="submit"
          text={t('download_report')}
          className={styles.sendBtn}
          disabled={disabledSendReport || isLoading}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default XlsReportFeedbacksPopoverContent;
