import { ApiError } from './api/api';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import AlertIcon from '../assets/svg/newSvg/alert.svg?react';
import '~/assets/css/toastify.css';
import i18next from 'i18next';

export function useToastError() {
  return (error?: unknown, shouldShowText?: boolean) => {
    const t = i18next.t;
    let message = '';
    let status;

    if (typeof error === 'string') {
      message = error;
    } else if (!(error instanceof ApiError)) {
      message = t('unknown_error');
      if (isAxiosError(error)) {
        try {
          message = error.response!.data[0].errors[0];
        } catch (e) {}
      }
    } else {
      message = error?.message;
      status = error.code;
    }

    shouldShowText = shouldShowText === undefined ? true : shouldShowText;

    return toast.error(
      <div className="toastContent">
        <span className="toastContentTitle">{message}</span>
        {shouldShowText && (
          <span className="toastContentText">
            {status === 401
              ? 'Неверный ввод логина или пароля'
              : 'Попробуйте позднее.'}
          </span>
        )}
      </div>,
      {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: status === 401 ? true : false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: status === 401 ? 'light' : 'colored',
        toastId: status === 401 ? undefined : 1111,
        className: 'toastError',
        icon: <AlertIcon />,
      }
    );
  };
}
