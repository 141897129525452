import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { instance } from '~/utils/api/api';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ErrorMessage } from '~/components/ErrorMessage/ErrorMessage';
import { Board } from '~/components/Board/Board';
import { TransactionsTable } from './TransactionsTable';
import { TableSelect } from '~/ui/TableSelect/TableSelect';
import { TableAmountPage } from '~/ui/TableAmountPage/TableAmountPage';
import { Button, InputBar, Pagination, Spinner } from '~/ui';
import '~/assets/css/select-table.css';
import styles from './TransactionsBoard.module.scss';
import FoodExportActions from '~/components/FoodExportActions/FoodExportActions';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Modal } from '~/components/Modal/Modal';
import TransactionsDishesModalContent from './TransactionsDishesModalContent/TransactionsDishesModalContent';
import { useToastError } from '~/utils/useToastError';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';

interface SelectedPage {
  selected: number;
}

interface FoodPoints {
  page: number;
}

export const TransactionsBoard = () => {
  const { initialValues, storedInitialValues, create } =
    useLocalStorage<FoodPoints>({
      initialValues: { page: 15 },
      key: 'transactions-points',
      exclude: [],
    });
  const [sort, setSort] = useState({
    field: '',
    direction: '',
  });
  const [perPage, setPerPage] = useState(
    storedInitialValues?.page || initialValues.page
  );
  const [page, setPage] = useState<number>();
  const [searchTerm, setSearchTerm] = useState('');
  const [date, setDate] = useState<Dayjs | null>(null);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const toastError = useToastError();
  const client = useQueryClient();
  const { user } = useContext(AuthenticationContext);
  const isDeleteAvailable = checkIfUserSuperAdmin(user?.data.role_name);
  const { t } = useTranslation();

  const params = {
    sort: sort.direction,
    ...(date ? { date: dayjs(date).format('YYYY-MM-DD') } : null),
    perPage,
    field: sort.field,
    query: debouncedSearchTerm,
    page,
  };

  const {
    data: transactions,
    isError,
    error,
    isFetching,
    refetch,
  } = useQuery({
    queryFn: async () => {
      const response = await instance.get(`admin/food-orders`, {
        params,
      });
      return response.data;
    },
    queryKey: ['transactions', params],
    keepPreviousData: false,
  });

  const handleSelect = (id: number) => {
    if (selectedItems.includes(id)) {
      const filteredTransactions = selectedItems.filter(
        selectedId => selectedId !== id
      );
      setSelectedItems(filteredTransactions);
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const handleToggleAll = () => {
    if (selectedItems.length === transactions?.data.length) {
      setSelectedItems([]);
    } else {
      const transactionIdArray = transactions?.data.map(
        (transaction: any) => transaction.id
      );
      setSelectedItems(transactionIdArray);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      await instance.delete('admin/delete-food-orders', {
        data: { order_ids: selectedItems },
      });
      setSelectedItems([]);
      client.invalidateQueries(['transactions']);
    } catch (error) {
      toastError(error);
    }
  };

  let content: React.ReactNode;

  if (transactions?.data) {
    content = (
      <TransactionsTable
        transactions={transactions?.data}
        sort={sort}
        setSort={setSort}
        handleToggleAll={handleToggleAll}
        handleSelect={handleSelect}
        selectedItems={selectedItems}
      />
    );
  } else if (isError) {
    content = <ErrorMessage error={error} />;
  } else {
    content = <Spinner />;
  }

  useEffect(() => {
    create({ page: perPage });
  }, [perPage]);

  useEffect(() => {
    refetch();
  }, [date]);

  return (
    <Board>
      <div className={styles.transactionsOptions}>
        <TableSelect perPage={perPage} setPerPage={setPerPage} />

        <InputBar
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          value={searchTerm}
          placeholder={t('search_by_id_n_order_np')}
          classNameInput={styles.input}
          label={`${t('search')}:`}
          star={false}
          classNameInputWrapper={styles.inputWrapper}
        />
        <div className={styles.tableHeadRightWrapper}>
          {!!selectedItems.length && isDeleteAvailable && (
            <Button
              text={t('delete')}
              color="red"
              onClick={handleDeleteSelected}
              className={styles.deleteButton}
            />
          )}
          <div className={styles.datePickerWrapper}>
            <DatePicker
              disabled={isFetching}
              placeholder={t('date_validate')}
              className={styles.datePicker}
              value={date ? dayjs(date) : null}
              onChange={date => {
                setDate(date ? date : null);
              }}
              format="DD.MM.YYYY"
            />
          </div>
          <FoodExportActions onOpenDishesClick={() => setIsModalOpen(true)} />
        </div>
      </div>
      {content}
      <div className={styles.pagination}>
        <TableAmountPage
          firstRow={transactions?.meta?.from}
          lastRow={transactions?.meta?.to}
          total={transactions?.meta?.total}
        />
        <Pagination
          pageCount={transactions?.meta?.last_page}
          onPageChange={(selectedPage: SelectedPage) => {
            setPage(selectedPage.selected + 1);
          }}
        />
      </div>
      <Modal
        size="full"
        modalStyles={styles.modal}
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      >
        <TransactionsDishesModalContent
          onCloseClick={() => setIsModalOpen(false)}
        />
      </Modal>
    </Board>
  );
};
