import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import EditQuestionnarieForm from './EditQuestionnaireForm/EditQuestionnarieForm';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

const EditQuestionnarie = () => {
  const toastError = useToastError();
  const { questionnaireID } = useParams();
  const { t } = useTranslation();

  const { data: questionnaire } = useQuery({
    queryFn: async () => {
      const response = await instance.get(
        `admin/questionnaires/${questionnaireID}`
      );
      return response.data;
    },
    queryKey: ['questionnaire', questionnaireID],
    onError: error => {
      toastError(error);
    },
  });

  return (
    <Page heading={<Heading text={t('edit_questionnaire')} />}>
      {questionnaire && <EditQuestionnarieForm questionnaire={questionnaire} />}
    </Page>
  );
};

export default EditQuestionnarie;
