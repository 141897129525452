import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Dropdown from '~/components/Dropdown';
import Actions, { Action } from '~/ui/Actions';
import EditIcon from './images/edit.svg?react';
import styles from './FoodAdminAction.module.scss';

interface IFoodAdminActionProps {
  id: number;
}

const FoodAdminAction = ({ id }: IFoodAdminActionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dropdown caption={t('actions')}>
      {closeDropdown => (
        <Actions>
          <Action
            onClick={() => {
              navigate(`/transactions/${id}`);
              closeDropdown();
            }}
            variant="primary"
          >
            <div className={styles.actionWrapper}>
              <EditIcon />
              <span>{t('to_order')}</span>
            </div>
          </Action>
        </Actions>
      )}
    </Dropdown>
  );
};

export default FoodAdminAction;
