import { DatePicker, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { BUTTON_STYLES, Button, QuerySelect, RadioInput } from '~/ui';
import styles from './XlsReportRequestsPopoverContent.module.scss';
import { useToastError } from '~/utils/useToastError';
import { useContext, useState } from 'react';
import { PopoverContext } from '~/components/XlsUploadFilePopover/XlsUploadFilePopover';
import { downloadMultipleAccommodationReportFile } from '~/utils/downloadReportFile';
import { useDebounce } from '@uidotdev/usehooks';
import { AccommodationItem } from '~/components/AccommodationForm';
import { PaginatedResponse } from '~/utils/types/common';
import { getAccommodations } from '~/utils/api/queries';
import { useTranslation } from 'react-i18next';

const XlsReportRequestsPopoverContent = () => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [typeReport, setTypeReport] = useState('1');
  const [accommodationIDs, setAccommodationIDs] = useState<number[] | []>([]);
  const [userID, setUserID] = useState<number | null>(null);
  const debouncedSearchTerm = useDebounce('', 500);
  const { setIsPopoverVisible } = useContext(PopoverContext);
  const toastError = useToastError();
  const { t } = useTranslation();

  const params = {
    sort: '',
    perPage: 100,
    field: '',
    query: debouncedSearchTerm,
    page: 1,
  };

  const onClosePopup = () => {
    setStartDate(null);
    setEndDate(null);
    setIsPopoverVisible(false);
    setAccommodationIDs([]);
    setUserID(null);
    setTypeReport('1');
  };

  // const { data: users } = useQuery({
  //   queryFn: async () => {
  //     const response = await instance.get('admin/users', { params });
  //     return response.data;
  //   },
  //   queryKey: ['users', params],
  //   onError: () => {
  //     toastError('Не удалось загрузить пользователей!');
  //   },
  // });

  const downloadFileReport = async () => {
    let typeReportPath: string;
    let typeReportName: string;

    if (typeReport === '1') {
      typeReportPath = `analytics-ticket-accommodation`;
      typeReportName = 'analytics-request-accommodation';
    } else if (typeReport === '2') {
      typeReportPath = `analytics-ticket-accommodation-date`;
      typeReportName = 'analytics-request-accommodation-details';
    } else if (typeReport === '3') {
      typeReportPath = `analytics-ticket-user-all/${userID}`;
      typeReportName = 'analytics-ticket-user';
    } else {
      return;
    }

    try {
      downloadMultipleAccommodationReportFile(
        typeReportPath,
        typeReportName,
        accommodationIDs,
        startDate,
        endDate
      );
    } catch (error) {
      toastError(error);
    }
    onClosePopup();
  };

  // function getAccommodationName(id: number) {
  //   const item = accommodations.find(item => item.id === id);

  //   return item ? item.name + '/' + item.section : '';
  // }

  const disabledSendReport =
    ((!startDate || !endDate) && typeReport === '2') ||
    (!accommodationIDs.length && typeReport === '1') ||
    (!userID && typeReport === '3');

  const disabledDate = (current: Dayjs) => {
    if (!startDate) {
      return current > dayjs().startOf('day');
    }

    return current < dayjs(startDate).startOf('day');
  };

  let content: React.ReactNode;

  if (typeReport === '1') {
    content = (
      <QuerySelect
        onChange={value => {
          setAccommodationIDs(value);
        }}
        value={accommodationIDs}
        queryFn={({
          pageParam = 1,
          meta,
        }): Promise<PaginatedResponse<AccommodationItem>> =>
          getAccommodations({
            sort: '',
            perPage: 100,
            field: '',
            query: meta?.searchQuery as any,
            page: pageParam,
          })
        }
        queryKey={['accommodations-announcement', params]}
        star={true}
        label={t('accommodation_facility')}
        multiple={true}
      />
    );
  } else if (typeReport === '2') {
    content = (
      <>
        <QuerySelect
          onChange={value => {
            setAccommodationIDs(value);
          }}
          value={accommodationIDs}
          queryFn={({
            pageParam = 1,
            meta,
          }): Promise<PaginatedResponse<AccommodationItem>> =>
            getAccommodations({
              sort: '',
              perPage: 100,
              field: '',
              query: meta?.searchQuery as any,
              page: pageParam,
            })
          }
          queryKey={['accommodations-announcement', params]}
          star={true}
          label={t('accommodation_facility')}
          multiple={true}
        />
        <div className={styles.dateWrapper}>
          <div className={styles.labelWrapper}>
            <span className={styles.labelText}>
              {t('date_from')}
              <span className={styles.labelStar}>*</span>
            </span>
            <Space direction="vertical" className="ant-space">
              <DatePicker
                className="datepicker"
                format="DD.MM.YYYY"
                value={startDate ? dayjs(startDate) : null}
                onChange={date => {
                  setStartDate(date ? date : null);
                  setEndDate(null);
                }}
                placeholder={t('date')}
                disabledDate={disabledDate}
              />
            </Space>
          </div>
          <div className={styles.labelWrapper}>
            <span className={styles.labelText}>
              {t('date_by')}
              <span className={styles.labelStar}>*</span>
            </span>
            <Space direction="vertical" className="ant-space">
              <DatePicker
                className="datepicker"
                format="DD.MM.YYYY"
                value={endDate ? dayjs(endDate) : null}
                onChange={date => {
                  setEndDate(date ? date : null);
                }}
                placeholder={t('date')}
                disabledDate={disabledDate}
              />
            </Space>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }

  // else if (typeReport === '3') {
  //   content = (
  //     <SelectBar<number | null>
  //       label="Пользователи"
  //       options={users.users.data}
  //       value={userID}
  //       onChange={value => {
  //         setUserID(value);
  //       }}
  //       star={true}
  //       name="user_id"
  //       el="full_name"
  //       disabled={false}
  //     />
  //   );
  // }
  return (
    <div className={styles.formWrapper}>
      <span className={styles.formTitle}>{t('request_analytics')}</span>
      <div className={styles.radioBtnTypeReportWrapper}>
        <RadioInput
          label={t('brief_report')}
          name="type_report"
          value="1"
          onChange={() => setTypeReport('1')}
          checkValue={typeReport}
          disabled={false}
        />
        <RadioInput
          label={t('full_report')}
          name="type_report"
          value="2"
          onChange={() => setTypeReport('2')}
          checkValue={typeReport}
          disabled={false}
        />
      </div>

      <div className={styles.contentWrapper}>{content}</div>

      <div className={styles.btnWrapper}>
        <Button
          onClick={onClosePopup}
          className={styles.contentBtn}
          text={t('cancel')}
          disabled={false}
          buttonStyle={BUTTON_STYLES.EMPTY}
        />
        <Button
          onClick={downloadFileReport}
          type="submit"
          text={t('download_report')}
          className={styles.sendBtn}
          disabled={disabledSendReport}
        />
      </div>
    </div>
  );
};

export default XlsReportRequestsPopoverContent;
