import { IconButton } from '~/ui';
import moment from 'moment-timezone';
import { getLocalTimeDate } from '~/utils/getLocalTimeDate';
import './popover.css';
import styles from './CommentMessage.module.scss';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '~/assets/svg/newSvg/basket.svg?react';
import ModalConfirm from '../ModalConfirm';

interface Comment {
  id: number;
  description: string;
  created_at: string;
}

interface User {
  full_name: string;
  role_name: string;
}

interface CommentsProps {
  comments: Comment[];
  user: User;
  onClick: (id: number) => void;
}

export const CommentMessage = ({
  comments,
  user,
  onClick,
}: CommentsProps): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <div className={styles.commentList}>
      {comments.map(({ id, description, created_at }: Comment) => (
        <div className={styles.commentItem} key={id}>
          <div className={styles.commentItemInfo}>
            <div className={styles.userWrapper}>
              <p className={styles.userName}>{user.full_name}</p>
              <span className={styles.userRole}>{user.role_name}</span>
            </div>
            <p className={styles.commentText}>{description}</p>
            <span className={styles.commentDate}>
              {getLocalTimeDate(moment(created_at).format())}
            </span>
          </div>
          <div className={styles.commentAction}>
            <ModalConfirm
              title={`${t('delete_comment_confirm_1')}\n${t(
                'delete_comment_confirm_2'
              )}`}
              onConfirm={() => onClick(id)}
            >
              {open => (
                <IconButton onClick={open}>
                  <DeleteIcon />
                </IconButton>
              )}
            </ModalConfirm>
          </div>
        </div>
      ))}
    </div>
  );
};
