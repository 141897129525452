import '~/assets/css/popover-xls.css';
import Breadcrumbs from '~/components/Breadcrumbs';
import cn from 'classnames';
import styles from './heading.module.scss';
import LoaderText from '../Shared/LoaderText/LoaderText';

interface HeadingProps {
  classNameText?: string;
  text?: string;
  actions?: React.ReactNode;
  arrowBtn?: React.ReactNode;
  popover?: React.ReactNode;
  classNameHeading?: string;
  classNameContainer?: string;
}

const Heading = ({
  classNameText,
  text,
  actions,
  arrowBtn,
  classNameHeading,
  classNameContainer,
}: HeadingProps): JSX.Element => {
  return (
    <div className={cn(styles.container, classNameContainer)}>
      <Breadcrumbs isLoading={text ? false : true} />
      <div className={cn(styles.heading, classNameHeading)}>
        <div className={styles.headingWrapper}>
          {arrowBtn}
          <LoaderText isLoading={text ? false : true}>
            <h2 className={cn(styles.headingText, classNameText)}>{text}</h2>
          </LoaderText>
        </div>
        <div className={styles.headingBtns}>{actions}</div>
      </div>
    </div>
  );
};

export default Heading;
