import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import AssetsBoard from './AssetsBoard/AssetsBoard';
import { instance } from '~/utils/api/api';
import { useTranslation } from 'react-i18next';
import { useToastError } from '~/utils/useToastError';
import { useQuery } from '@tanstack/react-query';

export interface IAsset {
  id: number;
  name: string;
  description: string;
  address: string;
  image_path: null | string;
  inn: string;
  color_id: number;
}

const Assets = (): JSX.Element => {
  const { t } = useTranslation();
  const toastError = useToastError();

  const { data: assets, isLoading } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/assets');
      return data.data;
    },
    queryKey: ['assets'],
    onError: error => {
      toastError(error);
    },
  });

  return (
    <Page heading={<Heading text={t('assets')} />}>
      <AssetsBoard assets={assets} isAssetsLoading={isLoading} />
    </Page>
  );
};

export default Assets;
