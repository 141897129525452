import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import styles from './TransactionsDishesTable.module.scss';
import { useTranslation } from 'react-i18next';
import Tooltip from '~/components/Shared/Tooltip/Tooltip';
import InfoIcon from '~/assets/svg/newSvg/info-icon.svg?react';
import { renderStatus } from '../../utils';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';

export interface Transaction {
  id: number;
  user_name: string;
  user_id: number;
  created_at: string;
  status_title: string;
  total_sum: string;
  food_name: string;
  has_cancel_wares: boolean;
}

interface Props {
  transactions: Transaction[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  selectedItems: {
    order_id: number;
    food_ware_ids: number[];
  }[];
  handleClickCheckbox: (id: number) => void;
}

export const TransactionsDishesTable = ({
  transactions,
  sort,
  setSort,
  selectedItems,
  handleClickCheckbox,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead field="user_name">{t('employee')}</TableCellHead>
        <TableCellHead sortable={false} field="user_id">
          {t('employee_id')}
        </TableCellHead>
        <TableCellHead sortable={false} field="id">
          {t('order_number')}
        </TableCellHead>
        <TableCellHead field="created_at">{t('order_date')}</TableCellHead>
        <TableCellHead sortable={false} field="status">
          {t('status')}
        </TableCellHead>
        <TableCellHead field="food_name">{t('food_object')}</TableCellHead>
        <TableCellHead field="total_sum">{t('order_price')}</TableCellHead>
      </TableHead>
      <TableBody>
        {transactions.map(
          ({
            id,
            user_name,
            user_id,
            created_at,
            status_title,
            food_name,
            total_sum,
            has_cancel_wares,
          }: Transaction) => (
            <TableRow key={id}>
              <TableCell className={styles.nameCell}>
                <CustomCheckbox
                  onChange={() => handleClickCheckbox(id)}
                  isChecked={!!selectedItems.find(item => item.order_id === id)}
                />
                <span className={styles.name}>{user_name}</span>
              </TableCell>
              <TableCell>{user_id}</TableCell>
              <TableCell>#{id}</TableCell>
              <TableCell>{created_at}</TableCell>
              <TableCell>
                <div className={styles.statusCell}>
                  {renderStatus(status_title)}

                  {has_cancel_wares ? (
                    <Tooltip
                      tooltipStyle={styles.tooltipStyle}
                      className={styles.transactionsTooltip}
                      title={t('request_closed_tooltip')}
                    >
                      <InfoIcon className={styles.tooltipIcon} />
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
              </TableCell>
              <TableCell>{food_name}</TableCell>

              <TableCell>{total_sum} &#8381;</TableCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
};
