import { useParams } from 'react-router-dom';
import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import { TransactionForm } from './TransactionForm';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useTranslation } from 'react-i18next';

import styles from './Transaction.module.scss';

export const Transaction = (): JSX.Element => {
  const { transactionID } = useParams();
  const toastError = useToastError();
  const { t } = useTranslation();

  const { data: order } = useQuery({
    queryFn: async () => {
      const response = await instance.get(`admin/food-orders/${transactionID}`);
      return response.data;
    },
    queryKey: ['transaction', transactionID],
    onError: error => {
      toastError(error);
    },
  });

  return (
    <Page
      heading={
        <Heading
          classNameContainer={styles.headingContainer}
          text={
            order?.id
              ? `${t('order')} #${order?.id} ${t('from')} ${order?.created_at}`
              : ''
          }
        />
      }
    >
      <TransactionForm transaction={order} />
    </Page>
  );
};
