import * as yup from 'yup';
import React, { useEffect, useRef, useState } from 'react';
import styles from './uploadPhoto.module.scss';
import UploadPhotoItem from './UploadPhotoItem/UploadPhotoItem';
import ErrorField from '~/ui/ErrorField';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const PhotoSchema = yup.object().shape({
  id: yup.number().required(),
  full_photo_url: yup.string().required(),
});

export const PhotosSchema = yup.array().of(PhotoSchema).required();

export const FilesSchema = yup
  .array()
  .of(yup.mixed<File>().required())
  .required();

export const UploadPhotosValueSchema = yup.object().shape({
  photos: PhotosSchema,
  files: FilesSchema,
});

export type UploadPhotosValue = yup.InferType<typeof UploadPhotosValueSchema>;

export type Photo = yup.InferType<typeof PhotoSchema>;

interface UploadPhotosProps {
  values: UploadPhotosValue;
  onChange: (values: UploadPhotosValue) => void;
  label: string;
  withError?: boolean;
  disabled?: boolean;
  isUploadBlocked?: boolean;
}

const UploadPhoto = ({
  values: { photos, files },
  onChange,
  label,
  disabled,
  isUploadBlocked = false,
  withError = false,
}: UploadPhotosProps): JSX.Element => {
  const [showPopover, setShowPopover] = useState(false);
  const [filesUrls, setFilesUrls] = useState<string[]>([]);
  const photoPicker = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = e.target.files;
    if (!newFiles) return;
    const updateFiles = Array.from(newFiles);

    onChange({ photos, files: [...files, ...updateFiles] });
  };

  const handleDeletePhotos = (index: number) => {
    const newPhotos = [...photos];
    newPhotos.splice(index, 1);

    onChange({ photos: newPhotos, files });
  };

  const handleDeleteFiles = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);

    onChange({ photos, files: newFiles });
  };

  useEffect(() => {
    const selectedUrls = files.map(file => URL.createObjectURL(file));

    setFilesUrls(selectedUrls);

    return () => {
      selectedUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, [files]);

  useEffect(() => {
    if (!showPopover) return;

    let timeout: number | undefined = 0;

    timeout = window.setTimeout(() => {
      setShowPopover(false);
    }, 3000);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [showPopover]);

  return (
    <div className={styles.uploadPhoto}>
      <span className={styles.uploadPhotoLabel}>{label}</span>
      <div className={styles.uploadPhotoContainer}>
        <div className={styles.imgContainer}>
          {filesUrls.map((url, index) => (
            <UploadPhotoItem
              key={url}
              url={url}
              onClick={() => handleDeleteFiles(index)}
              deletePhoto={!disabled}
            />
          ))}

          {photos.map(({ id, full_photo_url }, index) => (
            <UploadPhotoItem
              key={id}
              url={full_photo_url}
              onClick={() => handleDeletePhotos(index)}
              deletePhoto={!disabled}
            />
          ))}

          {isUploadBlocked ? (
            ''
          ) : (
            <div
              className={cn(
                styles.inputWrapper,
                disabled ? styles.inputWrapperDisabled : ''
              )}
            >
              <div className={styles.button}>{`${t('choose')} ${t(
                'file'
              )}`}</div>
              <span className={styles.description}>
                {`${t('size')}: 1000*200 px, ${t('max')} 2 ${t('mb')}`}
              </span>
              <input
                id="photos"
                type="file"
                multiple
                className={styles.inputPhoto}
                onChange={handleFilesChange}
                accept=".png, .jpg, .jpeg"
                ref={photoPicker}
                disabled={disabled || isUploadBlocked}
              />
            </div>
          )}
        </div>
      </div>
      {withError && <ErrorField name="uploadPhotosValue.files" />}
    </div>
  );
};

export default UploadPhoto;
