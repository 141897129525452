import { renderStatus, colorTime, renderCriticismIcon } from './utils';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButtonLink } from '~/ui';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './RequestsTable.module.scss';
import Timer from '~/pages/Requests/Timer';
import dayjs from 'dayjs';
import TooltipText from '~/components/Shared/TooltipText/TooltipText';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import { useContext } from 'react';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';

export interface Request {
  id: number;
  user_name: string;
  grade_name: string;
  start_date: string;
  category_name: string;
  description: string;
  ticket_status: number;
  ticket_processing: string;
  time_ticket: string;
  ticket_deadline: string;
  ticket_criticism_id: number | null;
}

interface IRequestsTableProps {
  requests: Request[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  handleToggleAll: () => void;
  handleSelect: (id: number) => void;
  selectedItems: number[];
}

export const RequestsTable = ({
  requests,
  sort,
  setSort,
  handleToggleAll,
  handleSelect,
  selectedItems,
}: IRequestsTableProps) => {
  const { t } = useTranslation();
  const client = useQueryClient();
  const { user } = useContext(AuthenticationContext);
  const isDeleteAvailable = checkIfUserSuperAdmin(user?.data.role_name);

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          {isDeleteAvailable ? (
            <TableCellHead
              className={styles.tableCellHead}
              sortable={false}
              field="checkbox"
            >
              <CustomCheckbox
                isChecked={
                  selectedItems.length === requests.length && !!requests.length
                }
                onChange={handleToggleAll}
              />
            </TableCellHead>
          ) : (
            ''
          )}
          <TableCellHead field="id">{t('request_number')}</TableCellHead>
          <TableCellHead field="grade_name">{t('user_type')}</TableCellHead>
          <TableCellHead field="start_date">
            {t('order_date_time')}
          </TableCellHead>
          <TableCellHead field="category_name">{t('category')}</TableCellHead>
          <TableCellHead field="description">{t('description')}</TableCellHead>
          <TableCellHead field="ticket_status">{t('status')}</TableCellHead>
          <TableCellHead sortable={false} field="actions">
            {t('action')}
          </TableCellHead>
          <TableCellHead sortable={false} field="ticket_status">
            {t('time_left')}
          </TableCellHead>
          <TableCellHead sortable={false} field="criticism_icon">
            {''}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {requests.map(
            ({
              id,
              grade_name,
              start_date,
              category_name,
              description,
              ticket_status,
              ticket_deadline,
              ticket_criticism_id,
            }: Request) => {
              if (
                ticket_status === 2 &&
                dayjs().isAfter(dayjs(ticket_deadline))
              ) {
                client.invalidateQueries({ queryKey: ['requests'] });
              }
              return (
                <TableRow key={id}>
                  {isDeleteAvailable ? (
                    <TableCell>
                      <CustomCheckbox
                        isChecked={selectedItems.includes(id)}
                        onChange={() => handleSelect(id)}
                      />
                    </TableCell>
                  ) : (
                    ''
                  )}
                  <TableCell>№ {id}</TableCell>
                  <TableCell>{grade_name}</TableCell>
                  <TableCell>
                    {dayjs(start_date).format('DD.MM.YYYY')}
                  </TableCell>
                  <TableCell>{category_name}</TableCell>
                  <TableCell className={styles.descriptionCell}>
                    <TooltipText text={description} />
                  </TableCell>
                  <TableCell>{renderStatus(ticket_status)}</TableCell>
                  <TableCell>
                    <div className={styles.requestsTableActions}>
                      <IconButtonLink
                        to={`/requests/${id}`}
                        className={styles.requestsTableBtnLink}
                      >
                        <Edit />
                      </IconButtonLink>
                    </div>
                  </TableCell>
                  <TableCell className={styles.timerCell}>
                    <Timer
                      colorTime={colorTime(ticket_status)}
                      expiryTime={ticket_deadline}
                      enabled={ticket_status === 2 ? true : false}
                    />
                  </TableCell>
                  <TableCell>
                    {renderCriticismIcon(ticket_criticism_id)}
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </div>
  );
};
