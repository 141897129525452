import { useParams } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useFormik } from 'formik';
import { Button, Textarea, ButtonLink } from '~/ui/index';
import styles from './RequestCommentForm.module.scss';
import { useTranslation } from 'react-i18next';

interface RequestCommentValues {
  description: string;
  ticket_id: string;
}

interface RequestItem {
  tickets: { ticket_comment: RequestCommentValues[]; ticket_id: number };
}

export const RequestCommentForm = (): JSX.Element => {
  const { requestID } = useParams();
  const client = useQueryClient();
  const toastError = useToastError();
  const { t } = useTranslation();

  const { mutate: createComment } = useMutation(
    async ({ description, ticket_id }: RequestCommentValues) => {
      const response = await instance.post('admin/ticket-comment', {
        description,
        ticket_id: Number(ticket_id),
      });
      return response.data;
    },
    {
      onSuccess: (data: RequestCommentValues) => {
        client.setQueryData(
          ['request', requestID],
          (oldTicket?: RequestItem) => {
            if (!oldTicket) return oldTicket;

            return {
              ...oldTicket,
              tickets: {
                ...oldTicket.tickets,
                ticket_comment: [...oldTicket.tickets.ticket_comment, data],
              },
            };
          }
        );
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const formik = useFormik<RequestCommentValues>({
    initialValues: {
      ticket_id: requestID || '',
      description: '',
    },

    onSubmit: (values: RequestCommentValues) => {
      createComment({
        description: values.description,
        ticket_id: values.ticket_id,
      });
      formik.resetForm();
    },
  });

  return (
    <div className={styles.formWrapper}>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className={styles.form}
      >
        <Textarea
          rows={4}
          label={t('comment')}
          placeholder={t('comment_text')}
          maxLength={600}
          value={formik.values.description}
          onChange={formik.handleChange}
          name="description"
          star={false}
          disabled={false}
        />

        <div className={styles.btnsWrapper}>
          <Button
            type="submit"
            text={t('send_comment')}
            className={styles.formBtn}
            disabled={false}
          />

          <ButtonLink
            text={t('back_to_list')}
            to="/requests"
            color="gray"
            className={styles.btnGray}
          />
        </div>
      </form>
    </div>
  );
};
