import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import { ButtonLink } from '~/ui';
import { AccommodationsBoard } from './AccommodationsBoard';
import { XlsUploadFilePopover } from '~/components/XlsUploadFilePopover/XlsUploadFilePopover';
import XlsReportAccommodationPopoverContent from './XlsReportAccommodationPopoverContent';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

const Accomodations = (): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const isCreateButtonShown = checkIfUserSuperAdmin(user?.data.role_name);
  const { t } = useTranslation();

  return (
    <Page
      heading={
        <Heading
          text={t('accommodation_facilities')}
          actions={
            <>
              {isCreateButtonShown && (
                <ButtonLink
                  text={t('add_new_object')}
                  to="/accommodations/create"
                />
              )}
              <XlsUploadFilePopover
                btnText={t('analytics_on_placements')}
                content={<XlsReportAccommodationPopoverContent />}
              />
            </>
          }
        />
      }
    >
      <AccommodationsBoard />
    </Page>
  );
};

export default Accomodations;
