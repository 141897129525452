import { instance } from '~/utils/api/api';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButton } from '~/ui';
import Eye from '~/assets/svg/newSvg/eye.svg?react';
import EyeHidden from '~/assets/svg/newSvg/eye-hidden.svg?react';
import cn from 'classnames';
import styles from './CurrentMenuTable.module.scss';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import { renderMeasure, renderPrice } from '../utils';

interface FoodTypes {
  name: string;
}

export interface CurrentMenuItem {
  id: number;
  wares: {
    id: number;
    code: string;
    wares_name: string;
    price_list: { price: string };
    weight: string;
    parent: string;
  };
  visibility: boolean;
  food_types: FoodTypes[];
}

interface FoodWaresValues {
  id: number;
  visibility: boolean;
}

interface Params {
  sort: string;
  perPage: number;
  field: string;
  query: string;
  page: number | null;
  date?: string;
  food_id: number;
}

interface Props {
  currentMenu: CurrentMenuItem[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  foodId: number;
  params: Params;
  selectedFoods: number[];
  handleSelect: (id: number) => void;
  handleToggleAll: () => void;
  updateCurrentMenu: (data: CurrentMenuItem[]) => void;
  t: (text: string) => string;
}

const CurrentMenuTable = ({
  currentMenu,
  sort,
  setSort,
  updateCurrentMenu,
  selectedFoods,
  handleSelect,
  handleToggleAll,
  t,
}: Props): JSX.Element => {
  const changeVisibilityStatus = async ({
    id,
    visibility,
  }: FoodWaresValues) => {
    const initialFoods = currentMenu;
    const updatedFoods = currentMenu.map(food => {
      if (food.id === id) {
        return { ...food, visibility: !food.visibility };
      }
      return food;
    });
    try {
      updateCurrentMenu(updatedFoods);
      const response = await instance.post(
        `admin/set-food-wares-visibility/${id}`,
        {
          visibility,
        }
      );
      return response.data;
    } catch (error) {
      updateCurrentMenu(initialFoods);
    }
  };

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead
          className={styles.tableCellHead}
          sortable={false}
          field="checkbox"
        >
          <CustomCheckbox
            isChecked={
              selectedFoods.length === currentMenu.length &&
              !!selectedFoods.length
            }
            onChange={handleToggleAll}
          />
        </TableCellHead>
        <TableCellHead sortable={false} field="id">
          {t('code')}
        </TableCellHead>
        <TableCellHead field="wares_name">{t('name')}</TableCellHead>
        <TableCellHead sortable={false} field="parent">
          {t('category')}
        </TableCellHead>
        <TableCellHead field="price">{t('price')}</TableCellHead>
        <TableCellHead sortable={false} field="weight">
          {t('measure')}
        </TableCellHead>
        <TableCellHead field="food_types">{t('service')}</TableCellHead>
        <TableCellHead sortable={false} field="actions">
          {t('action')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {currentMenu.map(
          ({ id, wares, visibility, food_types }: CurrentMenuItem) => {
            return (
              <TableRow key={id}>
                <TableCell hidden={!visibility}>
                  <CustomCheckbox
                    isChecked={selectedFoods.includes(id)}
                    onChange={() => handleSelect(id)}
                  />
                </TableCell>
                <TableCell>{wares?.code}</TableCell>
                <TableCell hidden={!visibility}>{wares?.wares_name}</TableCell>
                <TableCell className={styles.typeCell} hidden={!visibility}>
                  {wares?.parent ? wares?.parent : t('dish')}
                </TableCell>
                <TableCell className={styles.priceCell} hidden={!visibility}>
                  {renderPrice(wares?.price_list?.price)}
                </TableCell>
                <TableCell hidden={!visibility}>
                  {renderMeasure(wares?.weight)}
                </TableCell>
                <TableCell hidden={!visibility}>
                  {food_types ? food_types[0]?.name : '-'}
                </TableCell>
                <TableCell>
                  <div className={styles.actions}>
                    <IconButton
                      onClick={() =>
                        changeVisibilityStatus({ id, visibility: !visibility })
                      }
                      className={cn(styles.eyeBtn)}
                    >
                      {visibility ? <Eye /> : <EyeHidden />}
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            );
          }
        )}
      </TableBody>
    </Table>
  );
};

export default CurrentMenuTable;
