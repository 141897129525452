import { Outlet } from 'react-router-dom';
import Aside from '~/components/Aside/Aside';
import styles from './Layout.module.scss';
import Header from '../Header/Header';

const Layout = ({ children }: { children?: React.ReactNode }): JSX.Element => {
  return (
    <div className={styles.layout}>
      <Aside />
      <div className={styles.layout__wrapper}>
        <Header />
        <main className={styles.layout__main}>{children || <Outlet />}</main>
      </div>
    </div>
  );
};

export default Layout;
