import { DatePicker } from 'antd';
import React, { useEffect } from 'react';
import { TableCell, TableRow } from '~/ui/Table';
import DatePickerIcon from '~/assets/svg/datePicker/datePickerIcon.svg?react';
import styles from './EditRow.module.scss';
import { IconButton, QuerySelect, Select } from '~/ui';
import dayjs, { Dayjs } from 'dayjs';
import { ParamsProps, getAccommodations } from '~/utils/api/queries';
import { PaginatedResponse } from '~/utils/types/common';
import { AccommodationItem } from '~/components/AccommodationForm';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import CheckIcon from '~/assets/svg/newSvg/mark-icon.svg?react';
import { useTranslation } from 'react-i18next';
import { IRoom } from '~/components/UserForm/BusinessTripForm';

interface IEditRowProps {
  id: number;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  startDateToEdit: string;
  endDateToEdit: string;
  accommodationToEdit: AccommodationItem;
  accommodationId: number | null;
  roomId: number | null;
  params: ParamsProps;
  rooms: IRoom[];
  getRoom: (id: number) => string;
  setRoomId: (id: number) => void;
  setAccomodation: (id: number) => void;
  setStartDate: (date: Dayjs | null) => void;
  setEndDate: (date: Dayjs | null) => void;
  updateTrip: (id: number) => void;
  clickStopEditing: () => void;
  getAccommodation: (id: number) => string;
}

const EditRow: React.FC<IEditRowProps> = ({
  id,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  startDateToEdit,
  endDateToEdit,
  accommodationToEdit,
  accommodationId,
  roomId,
  rooms,
  getRoom,
  setRoomId,
  getAccommodation,
  setAccomodation,
  params,
  updateTrip,
  clickStopEditing,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    setStartDate(dayjs(startDateToEdit));
    setEndDate(dayjs(endDateToEdit));
    setAccomodation(accommodationToEdit.id);
  }, []);

  return (
    <TableRow key={id}>
      <TableCell>
        <DatePicker
          className={styles.BusinessTripDatePicker}
          allowClear={false}
          suffixIcon={<DatePickerIcon />}
          disabledDate={d => !d || d.isAfter(endDate)}
          format="DD.MM.YYYY"
          placeholder={t('date')}
          value={startDate}
          onChange={date => {
            setStartDate(date);
          }}
        />
      </TableCell>
      <TableCell>
        <DatePicker
          className={styles.BusinessTripDatePicker}
          allowClear={false}
          suffixIcon={<DatePickerIcon />}
          disabledDate={d => !d || d.isBefore(startDate)}
          format="DD.MM.YYYY"
          placeholder={t('date')}
          value={endDate}
          onChange={date => {
            setEndDate(date);
          }}
        />
      </TableCell>
      <TableCell>
        <QuerySelect
          onChange={value => {
            setAccomodation(value);
          }}
          value={accommodationId}
          queryFn={({
            pageParam = 1,
            meta,
          }): Promise<PaginatedResponse<AccommodationItem>> =>
            getAccommodations({
              sort: '',
              perPage: 100,
              field: '',
              query: meta?.searchQuery as any,
              page: pageParam,
            })
          }
          queryKey={['accommodations', params]}
          star={true}
          getValueText={getAccommodation}
        />
      </TableCell>
      <TableCell className={styles.roomCell}>
        <Select
          onChange={value => {
            setRoomId(value);
          }}
          value={roomId}
          size="small"
          options={rooms}
          star
          getValueText={getRoom}
        />
      </TableCell>
      <TableCell>
        <div className={styles.BusinessTripTableActions}>
          <IconButton
            onClick={() => updateTrip(id)}
            className={styles.BusinessTripTableBtnLink}
          >
            <CheckIcon />
          </IconButton>
          <IconButton onClick={clickStopEditing}>
            <Basket />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default EditRow;
