import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { useDebounce } from '@uidotdev/usehooks';
import { useFormik } from 'formik';
import { validate } from '~/utils/validateUser';
import { useLocalStorage } from '~/utils/useLocalStorage';
import * as Tabs from '@radix-ui/react-tabs';
import { Button } from '~/ui/index';
import '~/assets/css/datepicker.css';
import cn from 'classnames';
import styles from './UserForm.module.scss';
import { GeneralFormInfo } from './GeneralFormInfo';
import { BusinessTripForm } from './BusinessTripForm';
import { Accommodation } from '~/pages/Accomodotions/AccommodationsTable';
import { IUserRole, USER_ROLES } from '~/utils/getUserRole';
import { IAsset } from '~/pages/Assets/Assets';
import { AuthenticationContext } from '../Authentication/AuthenticationProvider';
import { useTranslation } from 'react-i18next';

export interface UserItem {
  id: number;
  full_name?: string;
  name: string;
  uniqueId: string;
  patronymic: string;
  phone: string;
  email: string;
  password: string;
  business_trips: IUserBusinessTrip[];
  company_name: null | string | undefined;
  grade: {
    id: number;
    name: string;
  } | null;
  role_id: number | null;
  accommodations_id: number | null;
  module: string;
  organization: string;
  accommodation_section: string;
  asset_id: number;
  have_edit_permission: { role: boolean };
  role_name: string;
  utc: string;
  display_role_name?: string;
  food_id?: number;
}

export interface UserBusinessTrips {
  id: number;
  user_id: number;
  start_date: string;
  end_date: string;
  accommodation_id: string | number;
  created_at: string;
  updated_at: string;
}

export interface IUserBusinessTrip {
  id: number;
  user_id: number;
  start_date: string;
  end_date: string;
  accommodation_id: number;
  created_at: string;
  updated_at: string;
  accommodation: Accommodation;
  roomId: number;
}

export interface UserValues {
  name: string;
  uniqueId: string;
  patronymic: string;
  phone: string;
  email: string;
  password: string;
  organization: string;
  company_name: null | string | undefined;
  grade_id?: number | null;
  grade: {
    id: number;
    name: string;
  } | null;
  role_id: number | null;
  accommodations_id: number | null;
  accommodation_section: string;
  business_trips: IUserBusinessTrip[];
  asset_id: number | null;
  utc: string;
  display_role_name?: string;
  food_id?: number;
}

interface UserFormProps {
  isLoading?: boolean;
  user?: UserItem;
  onSubmit: (values: UserValues) => void;
  storageKey?: string;
  isEditing?: boolean | undefined;
}

const ACTIVE_TABS = {
  INFO: 'info',
  CALENDAR: 'calendar',
};

export const UserForm = ({
  isLoading,
  user,
  onSubmit,
  isEditing,
  storageKey,
}: UserFormProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState(ACTIVE_TABS.INFO);
  const [disabledPassword, setDisabledPassword] = useState<boolean>(false);
  const [passwordValid, setPasswordValid] = useState<boolean>(false);
  const [assets, setAssets] = useState<IAsset[]>([]);
  const toastError = useToastError();
  const debouncedSearchTerm = useDebounce('', 500);
  const currentUser = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const params = {
    sort: '',
    perPage: 100,
    field: '',
    query: debouncedSearchTerm,
    page: 1,
  };

  const timezones = [
    { id: 'UTC +8', name: `+8 UTC (${t('Irkutsk')})` },
    { id: 'UTC +12', name: `+12 UTC (${t('Kamchatka')})` },
    { id: 'UTC +7', name: `+7 UTC (${t('Krasnoyarsk')})` },
    { id: 'UTC +11', name: `+11 UTC (${t('Magadan')})` },
    { id: 'UTC +7', name: `+7 UTC (${t('Novosibirsk')})` },
    { id: 'UTC +6', name: `+6 UTC (${t('Omsk')})` },
    { id: 'UTC +11', name: `+11 UTC (${t('Sakhalin')})` },
    { id: 'UTC +10', name: `+10 UTC (${t('Vladivostok')})` },
    { id: 'UTC +9', name: `+9 UTC (${t('Yakutsk')})` },
    { id: 'UTC +5', name: `+5 UTC (${t('Yekaterinburg')})` },
    { id: 'UTC +3', name: `+3 UTC (${t('Moscow')})` },
  ];

  const { data: roles } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/get-roles');
      return data;
    },
    queryKey: ['roles'],
    onError: error => {
      toastError(error);
    },
  });

  const { data } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/users-grade');
      return response.data;
    },
    queryKey: ['grades'],
    onError: error => {
      toastError(error);
    },
  });

  const grades = data?.grade;

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<UserValues>({
      initialValues: {
        name: '',
        uniqueId: '',
        patronymic: '',
        phone: '',
        email: '',
        password: '',
        organization: t('organisation'),
        company_name: null,
        grade: null,
        role_id: null,
        accommodations_id: null,
        accommodation_section: '',
        business_trips: [],
        asset_id: null,
        utc: '',
        food_id: 0,
      },
      key: storageKey && `${storageKey}-v1`,
      exclude: ['business_trips', 'role_id'],
    });

  const formik = useFormik({
    initialValues: storedInitialValues,

    validate,

    onSubmit: async (values: UserValues) => {
      try {
        await onSubmit(values);
        clear();
        formik.resetForm({ values: initialValues });
      } catch (error) {
        toastError(error);
      }
    },
  });

  const { data: accommodation } = useQuery(
    ['accommodation', formik.values.accommodations_id],
    async () => {
      const response = await instance.get(
        `admin/accommodations/${formik.values.accommodations_id}`
      );
      return response.data;
    },
    {
      enabled:
        formik.values.accommodations_id !== null &&
        formik.values.accommodations_id !== undefined,

      onError: error => {
        toastError(error);
      },
    }
  );

  create(formik.values);

  const setValues = formik.setValues;

  useEffect(() => {
    if (!user) return;

    setDisabledPassword(!!user?.password);

    setValues({
      name: user.name,
      uniqueId: user.uniqueId,
      patronymic: user.patronymic,
      phone: user.phone,
      email: user.email,
      password: user.password,
      business_trips: user.business_trips,
      company_name: user.company_name,
      role_id: user.role_id,
      grade: user.grade,
      accommodations_id: user.accommodations_id,
      organization: user.organization,
      accommodation_section: user.accommodation_section,
      asset_id: user.asset_id,
      display_role_name: user.display_role_name,
      utc: user.utc,
      food_id: user.food_id,
    });
  }, [user, setValues, storedInitialValues]);

  const loadAssets = async () => {
    try {
      const { data } = await instance.get('admin/assets');
      setAssets(data.data);
    } catch {
      toastError(t('failed_to_load_assets'));
    }
  };

  const selectedRole = roles?.find(
    (role: IUserRole) => formik.values.role_id === role.id
  );
  const isSelectedRoleAssetAdmin =
    selectedRole?.name === USER_ROLES.ASSET_ADMIN;

  const isSelectedRoleFoodAdmin = selectedRole?.name === USER_ROLES.FOOD_ADMIN;

  const userRoles = roles?.map((role: { id: number; display_name: string }) => {
    return { id: role.id, name: role.display_name };
  });

  useEffect(() => {
    formik.setFieldValue('selectedRole', selectedRole);
    if (isSelectedRoleAssetAdmin) {
      if (!assets.length) {
        loadAssets();
      }
      formik.setFieldValue('company_name', '');
    } else {
      formik.setFieldValue('asset_id', null);
    }
  }, [formik.values.role_id]);

  useEffect(() => {
    if (roles?.length === 1) {
      formik.setFieldValue('role_id', roles[0].id);
    }
  }, [roles]);

  console.log(formik.values);

  return (
    <Tabs.Root
      className={styles.tabsRoot}
      value={activeTab}
      onValueChange={activeTab => setActiveTab(activeTab)}
    >
      <Tabs.List className={styles.tabsList} aria-label="Manage your account">
        <Tabs.Trigger
          value={ACTIVE_TABS.INFO}
          className={cn(styles.tabsTrigger, {
            [styles.tabsTriggerActive]: activeTab === ACTIVE_TABS.INFO,
          })}
        >
          {t('general_information')}
        </Tabs.Trigger>
        <Tabs.Trigger
          value={ACTIVE_TABS.CALENDAR}
          className={cn(styles.tabsTrigger, {
            [styles.tabsTriggerActive]: activeTab === ACTIVE_TABS.CALENDAR,
          })}
        >
          {t('trips')}
        </Tabs.Trigger>
      </Tabs.List>
      <div className={styles.formWrapper}>
        <form
          className={styles.form}
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Tabs.Content
            tabIndex={undefined}
            className={styles.tabsContent}
            value={ACTIVE_TABS.INFO}
          >
            <GeneralFormInfo
              isLoading={isLoading}
              roleName={user?.display_role_name || ''}
              timezonesList={timezones}
              assets={assets}
              roles={userRoles}
              isUserCurrent={
                Number(user?.id) === Number(currentUser?.user?.data.id)
              }
              grades={grades}
              companyName={user?.company_name}
              disabledPassword={disabledPassword}
              setPasswordValid={setPasswordValid}
              setDisabledPassword={setDisabledPassword}
              formik={formik}
              isAssetSelectShown={isSelectedRoleAssetAdmin}
              isFoodSelectShown={isSelectedRoleFoodAdmin}
              isRoleEditable={user?.have_edit_permission?.role}
            />
          </Tabs.Content>
          <Tabs.Content
            tabIndex={undefined}
            className={styles.tabsContent}
            value={ACTIVE_TABS.CALENDAR}
          >
            <BusinessTripForm
              isEditing={isEditing}
              formik={formik}
              accommodation={accommodation}
              params={params}
            />
          </Tabs.Content>
          {formik.errors.business_trips && (
            <span className={styles.tripsErrors}>
              {String(formik.errors.business_trips)}
            </span>
          )}
          <Button
            type="submit"
            text={t('save_n_exit')}
            className={styles.userPageBtn}
            disabled={
              !((formik.isValid && formik.dirty) || formik.isSubmitting) ||
              !passwordValid
            }
          />
        </form>
      </div>
    </Tabs.Root>
  );
};
