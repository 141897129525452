import { useState } from 'react';
import styles from './ImageContainer.module.scss';
import cn from 'classnames';

interface IImageContainerProps {
  image: string;
  onClick: () => void;
}

const ImageContainer = ({ image, onClick }: IImageContainerProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  return (
    <>
      {isLoading && <div className={styles.loader} />}
      <button
        className={cn(
          styles.imagePreviewButton,
          styles.imagePreviewButtonLoading
        )}
        onClick={onClick}
      >
        <img
          className={cn(styles.foodImage, isLoading && styles.foodImageLoading)}
          src={image}
          width={80}
          height={50}
          onLoad={() => setIsLoading(false)}
        />
      </button>
    </>
  );
};

export default ImageContainer;
