import { toast } from 'react-toastify';
import AlertIcon from '../assets/svg/newSvg/success-alert.svg?react';
import '~/assets/css/toastify.css';

export function useToastSuccess() {
  return (message: string) => {
    return toast.success(
      <div className="toastContent">
        <span className="toastContentTitle">{message}</span>
      </div>,
      {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 201,
        icon: <AlertIcon />,
      }
    );
  };
}
