import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import { Select } from '~/ui';
import styles from './Settings.module.scss';
import { useToastError } from '~/utils/useToastError';
import { useEffect, useState } from 'react';
import { instance } from '~/utils/api/api';
import { ButtonWithChildren } from '~/ui/ButtonWithChildren/ButtonWithChildren';
import SpinnerCircle from '~/ui/SpinnerCircle/SpinnerCircle';
import { useToastSuccess } from '~/utils/useToastSuccess';

const Settings = (): JSX.Element => {
  const [settings, setSetting] = useState({});
  const { t, i18n } = useTranslation();
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();

  const languageData = [
    { id: 1, name: t('russian'), type: 'ru' },
    { id: 2, name: t('english'), type: 'en' },
  ];

  const getSettings = async () => {
    try {
      const { data } = await instance.get('admin/settings');
      setSetting(data);
    } catch (error) {
      toastError(error);
    }
  };

  const handleLanguage = (number: number) => {
    const currentLanguage = languageData.find(item => item.id === number);
    return currentLanguage?.type;
  };

  const handleLanguageName = (number: number) => {
    const currentLanguage = languageData.find(item => item.id === number);
    return currentLanguage?.name;
  };

  const getCurrentLanguageId = () => {
    return languageData.find(item => item.type === i18n.language)?.id;
  };

  const initialValues = {
    language: getCurrentLanguageId(),
    name: '',
    type: '',
  };

  console.log(settings);

  const formik = useFormik({
    initialValues,

    onSubmit: async values => {
      try {
        await instance.post('admin/settings', {
          language_id: values.language,
        });
        i18n.changeLanguage(values.type);
        formik.resetForm({
          values: { ...initialValues, language: values.language },
        });
        toastSuccess(t('settings_updated'));
      } catch (error) {
        toastError(error);
      }
    },
  });

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Page heading={<Heading text={t('settings')} />}>
      <div className={styles.formContainer}>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <Select
            label={t('language')}
            value={Number(formik.values.language)}
            onChange={value => {
              formik.setFieldValue('language', value);
              formik.setFieldValue('type', handleLanguage(value));
              formik.setFieldValue('name', handleLanguageName(value));
            }}
            name="language"
            options={languageData}
            star={false}
            size="small"
            search={false}
          />
          <ButtonWithChildren type="submit" className={styles.formBtn}>
            {formik.isSubmitting ? <SpinnerCircle /> : t('save_n_exit')}
          </ButtonWithChildren>
        </form>
      </div>
    </Page>
  );
};

export default Settings;
