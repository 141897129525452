import LoaderText from '~/components/Shared/LoaderText/LoaderText';
import BellIcon from './images/bell.svg?react';
import styles from './Notifications.module.scss';
import { Link } from 'react-router-dom';

const Notifications = ({ count }: { count: number }) => {
  return (
    <Link to={'/requests'} className={styles.notifications}>
      {count ? (
        <div
          style={String(count).length === 1 ? { padding: '2px 7px' } : {}}
          className={styles.notificationsCountContainer}
        >
          <span className={styles.notificationsCount}>
            <LoaderText size={13} isLoading={!count} text="22">
              {count}
            </LoaderText>
          </span>
        </div>
      ) : null}
      <BellIcon />
    </Link>
  );
};

export default Notifications;
