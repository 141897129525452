import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButton, IconButtonLink } from '~/ui';
import Basket from '~/assets/svg/newSvg/basket.svg?react';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './AccommodationsTable.module.scss';
import ModalConfirm from '~/components/ModalConfirm';
import { useContext } from 'react';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { IAsset } from '../Assets/Assets';
import TooltipText from '~/components/Shared/TooltipText/TooltipText';
import { useTranslation } from 'react-i18next';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import { useToastSuccess } from '~/utils/useToastSuccess';

export interface Accommodation {
  id: number;
  name: string;
  section: string;
  address: string;
  floors: string;
  rooms: string;
  beds: string;
  assets: IAsset;
}

interface UsersTableProps {
  accommodations: Accommodation[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  handleToggleAll: () => void;
  handleSelect: (id: number) => void;
  selectedItems: number[];
}

export const AccomodationsTable = ({
  accommodations,
  sort,
  setSort,
  handleToggleAll,
  handleSelect,
  selectedItems,
}: UsersTableProps) => {
  const client = useQueryClient();
  const { user } = useContext(AuthenticationContext);
  const isDeleteShown = checkIfUserSuperAdmin(user?.data.role_name);
  const isAssetsShown = checkIfUserSuperAdmin(user?.data.role_name);
  const { t } = useTranslation();
  const toastSuccess = useToastSuccess();

  const { mutate: deleteAccommodation } = useMutation(
    async (id: string) => {
      const response = await instance.delete(`admin/accommodations/${id}`);
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['accommodations']);
        toastSuccess(t('accommodation_removed'));
      },
    }
  );

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          <TableCellHead
            className={styles.tableCellHead}
            sortable={false}
            field="checkbox"
          >
            <CustomCheckbox
              isChecked={
                selectedItems.length === accommodations.length &&
                !!accommodations.length
              }
              onChange={handleToggleAll}
            />
          </TableCellHead>
          <TableCellHead field="name">{t('name')}</TableCellHead>
          <TableCellHead field="section">{t('section')}</TableCellHead>
          <TableCellHead field="address">{t('location')}</TableCellHead>
          {isAssetsShown && (
            <TableCellHead field="asset">{t('asset')}</TableCellHead>
          )}
          <TableCellHead field="floors">{t('floors')}</TableCellHead>
          <TableCellHead field="rooms">{t('rooms')}</TableCellHead>
          <TableCellHead field="beds">{t('sleeping_places')}</TableCellHead>

          <TableCellHead sortable={false} field="actions">
            {t('action')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {accommodations.map(
            ({
              id,
              name,
              address,
              floors,
              rooms,
              assets,
              beds,
              section,
            }: Accommodation) => (
              <TableRow key={id}>
                <TableCell>
                  <CustomCheckbox
                    isChecked={selectedItems.includes(id)}
                    onChange={() => handleSelect(id)}
                  />
                </TableCell>
                <TableCell className={styles.tableCell}>
                  <TooltipText text={name} />
                </TableCell>
                <TableCell>{section}</TableCell>
                <TableCell className={styles.tableCell}>
                  <TooltipText text={address} />
                </TableCell>
                {isAssetsShown && <TableCell>{assets?.name}</TableCell>}
                <TableCell>{floors}</TableCell>
                <TableCell>{rooms}</TableCell>
                <TableCell>{beds}</TableCell>
                <TableCell>
                  <div className={styles.usersTableActions}>
                    {isDeleteShown && (
                      <ModalConfirm
                        title={`${t('sure_to_delete')} «${name}»`}
                        onConfirm={() => deleteAccommodation(id.toString())}
                      >
                        {open => (
                          <IconButton onClick={open}>
                            <Basket />
                          </IconButton>
                        )}
                      </ModalConfirm>
                    )}
                    <IconButtonLink
                      to={`/accommodations/${id}`}
                      className={styles.usersTableBtnLink}
                    >
                      <Edit />
                    </IconButtonLink>
                  </div>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};
