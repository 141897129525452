import { limitString } from '~/utils/limitString';
import cn from 'classnames';
import { Popover } from 'antd';
import styles from './OrdersTable.module.scss';

function renderDescription(description: string) {
  return (
    <Popover
      content={<div>{description}</div>}
      placement="top"
      trigger="hover"
      className="popover"
    >
      <div className={styles.description}>{limitString(description, 26)}</div>
    </Popover>
  );
}

function renderStatus(status: string) {
  return (
    <div>
      {status === 'Готово' ? (
        <span className={cn(styles.status, styles.statusPurple)}>
          Готовится
        </span>
      ) : null}

      {status === 'Получено' ? (
        <span className={cn(styles.status, styles.statusGreen)}>Выполнено</span>
      ) : null}
    </div>
  );
}

export { renderDescription, renderStatus };
