import HeaderBar from './HeaderBar/HeaderBar';
import UserMenu from '~/components/UserMenu/UserMenu';
import styles from './header.module.scss';
import NotificationsContainer from './NotificationsContainer/NotificationsContainer';
import { useContext } from 'react';
import { AuthenticationContext } from '../Authentication/AuthenticationProvider';
import { checkIfFoodAdmin } from '~/utils/getUserRole';

const Header = (): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const isFoodAdminAccess = checkIfFoodAdmin(user?.data.role_name);

  return (
    <header className={styles.header}>
      <div className={styles.header__container}>
        <HeaderBar />
        <div className={styles.header__container_right}>
          {!isFoodAdminAccess && <NotificationsContainer />}
          <UserMenu />
        </div>
      </div>
    </header>
  );
};

export default Header;
