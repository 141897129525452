import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import { ButtonLink } from '~/ui';
import QuestionnaireBoard from './QuestionnairesBoard';
import { useTranslation } from 'react-i18next';

const Questionnaires = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Page
      heading={
        <Heading
          text={t('questionnaires')}
          actions={
            <ButtonLink
              text={t('add_new_questionnaire')}
              to="/questionnaires/create"
            />
          }
        />
      }
    >
      <QuestionnaireBoard />
    </Page>
  );
};

export default Questionnaires;
