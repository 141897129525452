import { UserPageInfo } from './UserPageInfo';

import Heading from '~/components/Heading/Heading';
import Page from '~/components/Page/Page';
import { UsersBoard } from './UsersBoard';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Users = (): JSX.Element => {
  const [userTripStatus, setTripStatus] = useState('');
  const { t } = useTranslation();

  return (
    <Page heading={<Heading text={t('all_employees')} />}>
      <UserPageInfo
        userTripStatus={userTripStatus}
        setTripStatus={setTripStatus}
      />
      <UsersBoard userTripStatus={userTripStatus} />
    </Page>
  );
};

export default Users;
